cx-page-layout.ContentPage1Template {
  @include container-max-widths();

  padding: 0 0 3rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 400px 60px rgba(0, 0, 0, 0.1);

  cx-page-slot {
    &:not(.TopHeaderSlot) {
      background-color: $ssab-white;
    }

    &.TopHeaderSlot {
      .cx-page-header-title {
        padding-left: 1.5rem;

        @include media-breakpoint-down(md) {
          padding-left: 1.5rem;
        }

        @include media-breakpoint-between(xs, sm) {
          padding-left: 1rem;
        }
      }
    }

    &.Section1 {
      margin-top: 100px;

      @include media-breakpoint-down(md) {
        margin-top: 80px;
      }

      @include media-breakpoint-between(xs, sm) {
        margin-top: 50px;
      }
    }

    &.Section3 {
      padding-bottom: 3rem;
    }
  }
}
