.coil-comparison-page {
  ssab-cx-coils-comparison-preview {
    display: block;
  }
}

ssab-cx-coils-comparison-preview {
  display: none;

  .list {
    color: $ssab-text-color;
    width: 100%;
    z-index: $zindex-sticky;
    position: fixed;
    bottom: 0;
    align-items: center;
    animation-name: slide-up;
    animation-duration: 300ms;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    display: block;
    transform: translate(0, 0);
    left: 0;
    right: 0;
    background: $ssab-white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    padding: 20px 0;

    @include media-breakpoint-up(lg) {
      height: 172px;
    }

    .list-content {
      @include container-max-widths();
      margin: auto;
      align-content: stretch;
      padding: 0 1rem;

      @include media-breakpoint-up(lg) {
        padding: 0 1.5rem;
      }

      .list-content-header {
        @include ssab-H7-12-min();
        display: inline-block;
        border-radius: 25px;
        padding: 5px 10px;
        margin-bottom: 20px;

        &.reference {
          background-color: var(--cx-color-primary);
          color: $ssab-white;
        }

        &.comparison {
          background-color: $ssab-button-download-background;
          color: var(--cx-color-primary);
        }
      }

      .items-container {
        @include addScrollBar();
        display: flex;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
          height: 105px;
        }

        .item {
          display: inline-block;
          padding: 10px;
          height: 88px;
          min-width: 246px;
          scroll-snap-align: start;
          background: $ssab-white;
          border: 1px solid $ssab-border-color-default;

          + .item {
            margin-left: 24px;
          }

          .cx-product-image-container {
            display: inline-block;
            margin-right: 10px;
          }

          .product-name-wrapper {
            @include ssab-paragraph-sm();
          }
        }
      }

      .list-products {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;

        @include media-breakpoint-down(md) {
          margin: 24px 0;
          padding-top: 24px;
          border-top: 1px solid var(--cx-color-primary);
        }

        @include media-breakpoint-up(lg) {
          margin: 0 24px;
          padding-left: 24px;
          border-left: 1px solid var(--cx-color-primary);
        }

        .items-container {
          .fade-div {
            position: -webkit-sticky;
            position: sticky;
            width: 100%;
            height: 88px;
            right: 0;

            .fade-right {
              float: right;
              background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
              height: 100%;
              width: 82px;
            }
          }
        }
      }

      .list-buttons {
        min-width: 13rem;

        .coil-clear-btn {
          line-height: 24px;
        }
      }
    }
  }
}

ssab-cx-coils-comparison {
  &.modal.show {
    .ssab-modal {
      .modal-dialog {

        .modal-content {
          overflow-y: auto;
          height: 800px;
          padding: 2.5rem;

          .modal-header {
            padding: 0;
            margin-bottom: 2.5rem;
            border-bottom: 0;
          }

          .modal-body {
            padding: 0;
          }
        }
      }
    }
  }

  h5 {
    @include ssab-H5-14-min();
    color: $ssab-main-color;
    margin-bottom: 20px;
  }

  .reference-item {
    padding: 10px;
    border: 1px solid $ssab-border-color-default;
    margin-bottom: 2.5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      gap: 2.5rem;
    }

    .cx-product-image-container {
      display: inline-block;
      margin-right: 10px;
    }

    .product-name-wrapper {
      @include ssab-paragraph();

      .header {
        @include ssab-text();
      }
    }

    b {
      @include ssab-H5-14();
    }

    .icon-color-box {
      height: 20px;
      width: 20px;
    }
  }

  .comparison-description {
    padding: 20px;
    margin-bottom: 20px;
    background-color: $ssab-product-background;
  }

  .risks-description {
    .risk {
      margin-right: 2.5rem;
    }
  }

  .risk {
    &.risk-yellow {
      color: $ssab-status-open;
    }

    &.risk-green {
      color: $ssab-status-delivered;
    }

    &.risk-na {
      color: $ssab-status-cancelled;
    }

    &.risk-red {
      color: $ssab-status-blocked;
    }
  }

  .comparison-table-wrapper {
    @include addScrollBar();
    overflow-x: auto;
    max-width: 100%;
    scroll-snap-type: x mandatory;

    .comparison-table {
      margin-bottom: 2.5rem;

      tr {
        th,
        td {
          background-color: $ssab-white;
          padding: 10px 20px;
          border-bottom: 1px solid $ssab-border-color-default;
          border-right: 1px solid $ssab-border-color-default;

          &:first-child {
            @include ssab-text();
            padding-left: 10px;
          }
        }

        .left-header {
          position: sticky;
          left: 0;
          z-index: +1;
          white-space: nowrap;

          &::after {
            position: absolute;
            content: "";
            height: 100%;
            top: 0;
            right: -1px;
            border-right: 1px solid $ssab-border-color-default;
          }
        }
      }

      .item {
        display: inline-block;
        min-width: 246px;

        .cx-product-image-container {
          display: inline-block;
          margin-right: 10px;
        }

        .product-name-wrapper {
          @include ssab-paragraph();

          .header {
            @include ssab-text();
          }
        }
      }
    }
  }
}
