ssab-cx-cart-item-list,
ssab-cx-cart-item-list-consignment,
ssab-order-confirmation-items {
  .cx-item-list {
    background-color: white;
    padding: 2rem 0;

    .item-header {
      padding: 3rem 1rem 3rem 5rem;
    }

    .cx-item-list-price {
      padding: 0;
    }

    .cx-item-list-table {
      padding: 1.5rem;
    }

    &-header {
      border-bottom: 2px solid var(--cx-color-primary);
      margin-bottom: $ssab-padding-margin-default;
      padding-bottom: $ssab-padding-margin-default;
    }

    &-row {
      border-bottom: 1px solid $ssab-border-color-default;
    }

    &-total {
      padding: 3rem 5rem;

      .total-sum {
        border-color: var(--cx-color-primary);
      }

      .splitted-orders {
        padding: 0 5rem;
      }
    }

    &-dates {
      div:not(:first-child) {
        color: $ssab-list-dates-color;
      }
    }
  }

  ssab-cx-cart-item-normal, ssab-cx-cart-item-consignment {
    > div {
      padding: 1rem 0;
      position: relative;
    }

    .item-alert {
      border: 2px solid var(--cx-color-danger);
    }

    .item-disabled {
      > [class*="col-"] {
        &.item-update {
          .cx-counter-value,
          .ng-value {
            opacity: 0.5;
            color: var(--cx-color-text);
          }
        }

        &:not(.item-update) {
          opacity: 0.5;

          .text-opacity {
            opacity: 1;
          }
        }
      }
    }

    .ssab-icon.icon-system-trash {
      position: absolute;
      right: 0px;
    }
  }

  ssab-datepicker-input .input-group {
    border: unset;
  }

  @include media-breakpoint-down(xl) {
    .cx-item-list {
      display: grid;

      .cx-item-list-table {
        display: table-cell;
        overflow-y: hidden;
        overflow-x: visible;

        .cx-item-list {
          &-header,
          &-row {
            width: $ssab-table-width-md;
          }
        }
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .cx-item-list {
      &-total {
        padding: 1.5rem 0 0 0;

        .splitted-orders {
          padding: unset;
          border-bottom: 1px solid var(--cx-color-gray-light);
        }
      }
    }
  }
}

.order-summary,
ssab-cx-order-details-items,
ssab-order-confirmation-items,
ssab-cx-cart-details,
ssab-cx-cart-header {
  .header-block {
    background-color: var(--cx-color-inverse);
    padding: 2rem 5rem;

    &.summary {
      background-color: var(--cx-color-primary);
    }
  }

  .item-block {
    .item-header {
      padding: 3rem 0;
    }
  }

  @include media-breakpoint-only(md) {
    .header-block {
      padding: 1.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    .item-block {
      .item-header {
        padding: 0 1rem 1.5rem 1rem;
      }
    }

    .cx-item-list .item-header {
      padding: 0 1.5rem 1.5rem 1.5rem;
    }

    .cx-item-list-total {
      padding: 2rem 1.5rem 1.5rem 1.5rem;
    }

    .header-fields,
    &.cart-header {
      &.row {
        margin: unset;

        [class*="col-"] {
          padding: unset;
        }
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .header-block {
      padding: 1.5rem 1rem 1rem 1rem;
    }

    .cx-item-list .item-header {
      padding: 0 1rem 1rem 1rem;
    }

    .cx-item-list-total {
      padding: 2rem 1rem 1rem 1rem;
    }
  }
}

.order-summary .contact-details,
.order-summary .delivery-details {
  .header-block {
    padding: 1.5rem 1rem;

    @include media-breakpoint-up(sm) {
      padding: 2rem 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 2rem 5rem;
    }
  }
}

ssab-order-confirmation-items .cx-order-items {
  .items-details.header-block {
    padding: 0;
  }

  .cx-item-list-total {
    padding: 1.5rem 1rem;

    @include media-breakpoint-up(sm) {
      padding: 2rem 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 2rem 5rem;
    }

    .splitted-orders {
      padding: 0;
    }
  }

  ssab-cx-cart-totals .order-totals {
    padding: 1.5rem 1rem;

    @include media-breakpoint-up(md) {
      padding: 0 0 1rem 1rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem 3rem;
    }
  }
}

ssab-cx-order-confirmation-thank-you-message {
  padding: 0;

  h2 {
    padding: 0.75rem 1rem 2.25rem;

    @include media-breakpoint-up(md) {
      padding: 2rem 1.5rem 3.75rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 1.25rem 0 3rem;
    }
  }

  .cx-order-confirmation-message {
    background-color: var(--cx-color-primary);
    color: var(--cx-color-inverse);
    padding: 1.5rem 1rem;

    @include media-breakpoint-up(md) {
      padding: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 2rem 5rem;
    }

    h4 {
      text-align: left;

      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
  }
}

ssab-cx-cart-item-list-consignment {
  .cx-item-list {
    &-row {
      border-top: 2px solid var(--cx-color-primary);
      border-bottom: unset;
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 1rem;
      }
    }

    &-header {
      border-bottom: unset;
    }
  }
}

ssab-cx-cart-item-consignment {
  .cx-item-consignment {
    position: relative;

    &.has-border {
      border-bottom: 1px solid $ssab-border-color-default;
    }

    &.item-error::before {
      background-color: var(--cx-color-danger);
    }

    &.has-left-limiter:before {
      content: "";
      height: 60%;
      width: 2px;
      position: absolute;
      left: 0;
      top: 20%;
      background-color: var(--cx-color-primary);
    }

    [class*="col-"] {
      display: flex;
      align-items: center;
    }

    .ssab-icon.icon-system-trash {
      top: 50%;
      transform: translateY(-50%);
    }

    .ssab-consignment-label-button.icon-general-checklist-active {
      background-color: #ADD8E6;
    }

    .ssab-consignment-label-button {
      margin-left: 1vw;
      border: 0;
      background-color: transparent;
    }
  }
}

ssab-consignment-list {
  .table {

    thead {
      position: sticky;
      top: 0;
      z-index: 10;
      height: unset;
      background-color: var(--cx-color-primary);

      th {
        padding: 0.5rem;
        color: white;
        text-align: left;
        white-space: nowrap;
      }
    }

    td {
      padding: 0 0.5rem;

      ssab-cx-item-stepper {
        .cx-counter-stepper {
          input {
            &.form-control {
              height: 32px;
              margin: 0.5rem 0;
              max-width: 100px;
            }
          }
        }
      }
    }

    &-scroll {
      max-height: 300px;
      display: block;
      @include addScrollBar();
      @include media-breakpoint-down(sm) {
        max-width: 325px;
      }
    }

    &-responsive {
      overflow-x: hidden;
    }
  }
}

ssab-cx-cart-header,
ssab-order-confirmation-header {
  .header-total {
    background-color: white;
    padding: 1.5rem 5rem;

    .total-sum {
      border-color: var(--cx-color-primary);
    }

    .splitted-orders {
      padding: 0 5rem;
    }
  }
}

.cdk-overlay-container {

  .mdc-dialog__container {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    max-width: 90vw; // Ensure it doesn't overflow on small screens
    border: 2px solid var(--cx-color-primary);
    display: flex;
    flex-direction: column;
    height: initial;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    .dialog-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px; // Space between title and form field
      width: 100%;
      border-bottom: 1px solid #e0e0e0; // Bottom border to match the style
      padding-bottom: 10px;
    }

    .dialog-form-field {
      width: 100%; // Make the form field take up full width

      mat-label {
        font-weight: normal; // Adjust label font weight if needed
      }

      input {
        font-size: 14px; // Adjust font size for input if needed
      }
    }
  }

  .dialog-actions {
    display: flex;
    justify-content: end;
    margin-top: 20px; // Space between form field and actions
    width: 100%;

    button {
      min-width: 100px; // Minimum width for buttons
      font-weight: bold; // Make button text bold
      text-transform: uppercase;
    }

    button:nth-child(2) {
      background-color: var(--cx-color-primary); // Primary button color
      color: white; // Primary button text color
    }
  }
}
