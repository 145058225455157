ssab-cx-global-message,
ssab-cx-alert,
ssab-cx-order-data-tables-feedback,
ssab-cx-notifications {
  @extend %cx-global-message;

  position: sticky;

  .alert {
    align-items: center;
    color: var(--cx-color-inverse);
    font-size: 1.2rem;
    font-weight: normal;
    min-height: 50px;
    padding: 0 2rem 0 0;
    text-align: left;

    @include media-breakpoint-up(lg) {
      text-align: center;
    }

    .container {
      position: relative;
      padding: 0.75rem 1rem;
      @include media-breakpoint-up(lg) {
        padding: 1rem;
      }
    }

    .close {
      opacity: 1;
      right: -1.5rem;
      top: 25%;
      width: 30px;
      transform: translateY(-50%);

      &::before {
        margin: 0 1rem;
      }

      .icon-navigation-close {
        height: 50px;
        min-width: 30px;
      }
    }

    .alert-icon cx-icon,
    button ssab-icon {
      color: var(--cx-color-inverse);
    }

    &-warning {
      background-color: $ssab-warning-yellow;
    }

    &-danger {
      background-color: $ssab-attention-red;
    }

    &-success {
      background-color: $ssab-success-green-bg
    }

    &-info {
      background-color: var(--cx-color-info);
    }

    &-notification {
      background-color: var(--cx-color-primary);
    }

    &-icon {
      margin-bottom: -2px;
    }

    .extra-content{
      padding-top: inherit;
      color: var(--cx-color-primary);
    }

  }
}

ssab-cx-global-message {
  top: 6rem;
}

ssab-cx-notifications {
  z-index: 1055;
  position: inherit;
}
