ssab-cx-customer-select {
  .ng-select {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          color: var(--cx-color-text) !important;
        }
      }
    }

    .ng-select-container {
      border: unset;
    }

    .ng-dropdown {
      &-header {
        input {
          border: unset;
          display: inline;
          width: 90%;
        }
      }

      &-panel {
        min-width: 350px;

        &-items {
          &::-webkit-scrollbar {
            width: 14px;
            height: 18px;
            border-left: 1px solid var(--cx-color-gray-light);
          }

          &::-webkit-scrollbar-thumb {
            height: 6px;
            border: 3px solid $ssab-white;
            background-clip: padding-box;
            -webkit-border-radius: 7px;
            background-color: var(--cx-color-gray-light);
          }

          &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
            display: none;
          }

          &::-webkit-scrollbar-corner {
            background-color: transparent;
          }

          .ng-option {
            border-bottom: 1px solid var(--cx-color-gray-light);;
          }
        }
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .ng-select {
      .ng-dropdown-panel {
        min-width: auto;
      }
    }
  }
}
