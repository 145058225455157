ssab-cx-banner-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  column-gap: 4rem;
  row-gap: 5rem;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-between(xs, sm) {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    column-gap: 1rem;

    cx-media img {
      max-height: 300px;
    }
  }
}
