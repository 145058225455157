@mixin addScrollBar() {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: var(--cx-color-gray-light);
  }
}


@mixin datePickerTabletAndMobile() {
  ssab-datepicker-input {
    .input-group {
      height: $ssab-input-height-mobile;
      position: relative;

      .input-label {
        ngb-datepicker {
          top: 1rem !important;
        }

      }

      &-append {
        .icon-chevron-down {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}

@mixin selectTabletAndMobile() {
  > label {
    position: absolute;
    z-index: 1002;
  }
  .ng-select, &.ng-select.ng-select-single {
    .ng-select-container {
      height: $ssab-input-height-mobile;
      border-width: 0px;

      .ng-arrow-wrapper {
        position: absolute;
        top: 0;
        right: 0;
      }

      .ng-value-container {
        .ng-placeholder {
          padding-bottom: unset;
          padding-top: 1rem;
        }

        .ng-input {
          top: unset;
          bottom: 0.5rem;
          padding-left: unset;
        }
      }
    }
  }
}


@mixin tabTabletAndMobile() {
  background-color: $ssab-white;
  color: var(--cx-color-text);
  padding: 1rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

@mixin wideOption() {
  .option-header {
    display: none;
  }

  @include media-breakpoint-down(md) {
    &.cx-wide-option {
      position: fixed;
      top: 0;
      background-color: $ssab-white;
      z-index: 1003;
      height: 96vh;
      padding-top: 0;
      padding-bottom: 0;

      .option-header {
        display: block;
        padding: 2rem 0;
        margin: 0;
      }

      label.text-lg {
        display: none;
      }

      ng-select {
        &.ng-select {
          &,
          &.ng-select-multiple {
            .ng-select-container {
              padding-top: 0;

              .ng-arrow-wrapper {
                display: none;
              }
            }
          }
        }
      }

      ssab-datepicker-input {
        .input-group {
          height: 60%;
          position: relative;
          top: 30%;
          transform: translateY(-50%);
          margin: auto;
          width: 70%;

          label {
            display: none;
          }

          .input-label {
            ngb-datepicker {
              top: 0 !important;
            }

          }

          &-append {
            .icon-chevron-down {
              display: none;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-between(xs, sm) {
    &.cx-wide-option {
      ssab-datepicker-input {
        .input-group {
          width: 90%;
          top: 0;
          transform: none;
        }
      }
    }
  }
}

@mixin searchFormAndTable() {
  &-sorting {
    background-color: $ssab-white;
    padding: 1.5rem;
  }
  &-form {
    .cx-search-form {
      label.text-lg {
        color: black;

        @include media-breakpoint-up(lg) {
          color: $ssab-white;
          min-height: 25px; //allign labels without an icon
        }
      }

      ssab-cx-input-range {
        label.text-lg {
          color: black;
          font-size: 16px;
          line-height: 27px;
        }

        .btn-link {
          color: $ssab-main-color;
        }

        .range-content {
          position: absolute;
          background-color: $ssab-white;
          z-index: 10;
          padding-top: 10px;
          border: 1px solid $ssab-border-color-default;
        }
      }

      .btn-link:not(.ngb-dp-arrow-btn):not(.btn-range-clear):not(.btn-reset) {
        @include media-breakpoint-up(lg) {
          color: $ssab-white;
        }
      }
    }
  }
  &-table {
    background-color: $ssab-white;
    border-style: unset;

    tr {
      td, th {
        border-top: unset;

        &:last-child {
          text-align: left;
        }
      }

      td {
        padding: 1rem;
        word-break: break-word;
        width: inherit;
        display: table-cell;

        &.order-data-table {
          padding: 0;
        }
      }
    }

    thead {
      background-color: var(--cx-color-primary);

      th {
        color: var(--cx-color-inverse);
        padding: 1.5rem 1rem;

        &.th-wider {
          min-width: 170px;
        }
      }
    }
  }

  &-search {
    &-option {
      @include wideOption();
    }
  }

  @include media-breakpoint-down(md) {
    &-form {
      background-color: $ssab-white;
      border: 1px solid var(--cx-color-gray-light);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: unset;
      z-index: 10;
      height: 96vh;

      .cx-search-form {
        max-width: var(--cx-page-width-max);
        height: 100%;
        overflow: scroll;
        margin: auto;
        padding-top: 2rem;

        .btn-link {
          color: var(--cx-color-primary);
        }

        h3.d-md-block {
          border-bottom: 1px solid var(--cx-color-gray-light);
        }

        ssab-cx-input.input-no-border input, ssab-datepicker-input .input-group, ng-select .ng-select-container {
          border: unset;
        }

        ssab-cx-input-range {
          .range-content {
            position: relative;
            border: unset;
          }
        }
      }
    }

    &-search {
      margin: unset;
      color: $ssab-text-color;
      padding-bottom: 0;
      padding-top: 2rem;

      @include media-breakpoint-down(md) {
        min-height: 75vh;
      }

      &-option {
        border-bottom: 1px solid var(--cx-color-gray-light);
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        .ssab-icon {
          filter: unset;
        }

        &.form-group {
          > ssab-cx-input {
            input.form-control {
              padding: 0;

              &:focus {
                background-color: $ssab-white;
              }
            }
          }
        }

        ng-select {
          &.ng-select {
            &,
            &.ng-select-multiple {
              .ng-select-container {
                padding-top: 30px;
                padding-bottom: 0;
                height: auto;

                .ng-value-container {
                  padding-top: 0;
                  padding-left: 0;
                  height: $ssab-input-height-default;
                  max-width: 90vw;

                  .ng-placeholder {
                    padding-top: 0;
                    padding-left: 0;
                  }

                  .ng-value {
                    line-height: $ssab-input-height-default;
                  }

                  .ng-input {
                    top: 0;
                    bottom: 0;

                    input {
                      line-height: $ssab-input-height-default;
                    }
                  }
                }

                .ng-arrow-wrapper {
                  transform: rotate(-90deg);
                }
              }

              .ng-dropdown-panel {
                .ng-dropdown-footer {
                  padding: 8px 10px;

                  .multi-checkbox-all {
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        ssab-datepicker-input {
          .input-group {
            height: auto;

            .input-group-append {
              &.input-calendar-icon {
                text-align: left !important;
                width: 2rem;

                .icon-system-calendar {
                  margin: 0 !important;
                }
              }

              .icon-chevron-down {
                transform: rotate(-90deg);
              }
            }

            .input-label {
              vertical-align: middle;

              input {
                height: $ssab-input-height-default;
                padding: 0;
                margin: 0;
              }
            }
          }
        }

        ssab-cx-input-range {
          label {
            &.range-label {
              &.range-control {
                &.text-lg {
                  padding: 0;
                  height: $ssab-input-height-default;
                  line-height: $ssab-input-height-default;

                  .ssab-icon {
                    margin-top: -30px;
                    transform: rotate(-90deg);
                  }
                }
              }
            }
          }

          .range-content {
            padding-left: 0;
            padding-right: 0;
          }
        }

        ngb-popover-window {
          .popover-body {
            margin: 0 1rem;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      &-select {
        @include selectTabletAndMobile();
      }
    }
  }
}

@mixin popoverInMobile() {
  .popover {
    max-width: 100%;
    min-height: 100%;
    transform: unset !important;
    top: 2rem !important;

    .popover-body {
      padding: 1rem;
      min-width: 100vw;
      box-shadow: $ssab-nav-box-shadow-inner;
      -webkit-box-shadow: $ssab-nav-box-shadow-inner;
      -moz-box-shadow: $ssab-nav-box-shadow-inner;
    }

    .arrow {
      display: none;
    }
  }
}

@mixin container-max-widths($maxwidths: $container-max-widths, $breakpoints: $grid-breakpoints-min) {
  @each $breakpoint, $container-max-width in $maxwidths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}
