cx-page-layout.StoreSelectorPageTemplate {
  margin: auto;

  ssab-cx-store-selector {
    @include container-max-widths();

    @include media-breakpoint-up(lg) {
      padding: 0 1.5rem;
    }
  }

  cx-page-slot.SiteLogin {
    padding-right: 2rem;

    @include media-breakpoint-up(lg) {
      padding-right: 6rem;
    }
  }
}
