ssab-datepicker-day {

  .custom-day {
    border-radius: 20px;
    text-align: center;
    width: 26px;
    height: 26px;
    line-height: 26px !important;
    margin: 3px auto;
  }

  .custom-day.disabled {
    color: $ssab-gray-light;
    opacity: 0.5;
  }

  .custom-day.today,
  .custom-day.focused {
    color: $ssab-blue-light;
  }

  .custom-day.has-content {
    color: $ssab-blue-darker;
    opacity: 1;
  }

  .custom-day.selected,
  .custom-day:hover {
    color: var(--cx-color-inverse);
    background-color: var(--cx-color-primary);
    opacity: 1;
  }
}
