ssab-cx-hero {
  background-color: $ssab-main-color-darker;

  cx-media img {
    width: 100%;
    height: 100%;
  }

  article {
    background: linear-gradient(340.42deg, rgba(0, 38, 100, 0.79) -2.16%, rgba(0, 38, 100, 0.31) 128.9%);
    color: $ssab-white;
    min-height: $ssab-banner-height;
    padding: unset;

    @include media-breakpoint-up(lg) {
      padding: 4.5rem 0;
    }

    @include media-breakpoint-up(xxl) {
      max-height: 660px;
      flex-wrap: nowrap !important;
    }

    .banner-content {
      section {
        padding:  3rem 1.5rem;
        position: relative;

        @include media-breakpoint-up(lg) {
          position: absolute;
          padding: 0 4rem;
          top: 60%;
          transform: translateY(-50%);
        }
      }
    }

    cx-media {
      width: 100%;
      height: 100%;

      img{
        object-fit: cover;
      }
    }

    &.wide-image {
      padding: unset;
      background-color: unset;

      cx-media {
        position: absolute;
        width: 100%;
        height: 93vh;
        z-index: -10;
      }
    }
  }
}
