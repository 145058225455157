cx-page-layout {
  &.LoginPageTemplate {
    ssab-cx-banner {
      padding: 1.5rem;
      background-color: $ssab-white;
    }

    .LeftContentSlot {
      filter: drop-shadow($ssab-template-drop-shadow);
    }

    @include media-breakpoint-only(md) {
      cx-page-slot > * {
        max-width: unset;
      }
      .LeftContentSlot {
        max-width: 70%;
      }
    }

    @include media-breakpoint-down(sm) {
      background-color: $ssab-white;

      .LeftContentSlot {
        padding: unset;

        ssab-cx-banner,
        ssab-cx-login-form {
          padding: 0.75rem;
        }
      }
    }
  }

  &.CartPageTemplate {

    margin: auto;

    .CenterRightContentSlot {
      --cx-flex-basis: 100%;
    }

    .TopContent, .EmptyCartMiddleContent {
      padding-top: 3rem;
    }

    .EmptyCartMiddleContent {
      padding-bottom: 3rem;
    }

    ssab-cx-cart-details, .cart-header {
      filter: drop-shadow($ssab-template-drop-shadow);
    }

  }

  &.LandingPage2Template {
    max-width: 100%;
    background-color: $ssab-white;

    cx-page-slot {
      &.Section5 {
        @include container-max-widths();
        margin: auto;
      }

      &.Section1 {
        margin-top: unset;
        @include container-max-widths();

        &.has-components {
          padding: 2rem 1.5rem;

          @include media-breakpoint-down(sm) {
            padding: 2rem 0;
          }
        }
      }

      &.Section3 {
        max-width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        text-transform: uppercase;
        overflow-x: auto;

        &.has-components {
          padding: 0.25rem 0rem 0.25rem 4rem;
        }

        @include container-max-widths();

        ssab-cx-navigation-ui .heading-wrapper {
          min-width: unset;

          a:hover {
            color: $ssab-secondary-color;
          }

          @include ssab-text();
        }

        @include media-breakpoint-down(md) {
          ssab-cx-navigation-ui .heading-wrapper {
            @include ssab-paragraph();
          }
          padding-left: unset;
        }
        @include media-breakpoint-down(sm) {
          &.has-components {
            padding: 0;
            justify-content: start;

            .cx-no-link {
              padding: 0;

              > div {
                padding: 0.75rem 0.5rem;
              }
            }

            ssab-cx-navigation-ui {
              &.flyout cx-generic-link a {
                padding: 0.75rem 0.5rem;
              }

              .heading-wrapper {
                @include ssab-paragraph-sm();
              }
            }
          }
        }
      }

      &.Section4 {

        color: $ssab-main-color;
        margin: auto;
        padding: 6rem 1.5rem;
        background-color: $ssab-banner-background-color;

        @include media-breakpoint-down(md) {
          padding: 1.5rem;

          > * {
            border-bottom: unset;
            border-inline-end: unset;
          }
        }
        @include media-breakpoint-down(sm) {
          padding: 2rem 1.5rem 3rem 1.5rem;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.CartPageTemplate {
      padding: unset;

      .TopContent,
      .EmptyCartMiddleContent {
        padding-top: 0rem;
      }
    }

    &.OrderConfirmationPageTemplate {
      padding: unset;
    }
  }

  @include media-breakpoint-between(xs, sm) {
    &.SiteContext {
      ssab-cx-customer-select {
        display: none !important;
      }
    }
  }

  &.CartPageTemplate {
    @include container-max-widths();

    .EmptyCartMiddleContent {
      padding-bottom: 1rem;
    }
  }
}

ssab-cx-storefront {
  &.LoginPageTemplate {
    cx-page-slot.SiteContext {
      padding-right: 8rem;
    }

    @include media-breakpoint-down(md) {
      cx-page-slot.SiteContext {
        padding-right: 6rem;
      }

      .SiteContext {
        .Language {
          margin-right: 2.5rem;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      cx-page-slot.SiteContext {
        padding-right: 3rem;
      }
    }
  }
}
