ssab-cx-pagination {
  @extend %cx-pagination;
  a {
    background-color: $ssab-white;

    &.disabled.end, &.disabled.start {
      background-color: var(--cx-color-gray-light);
      border: 1px solid var(--cx-color-gray-light);
    }
  }

  @include media-breakpoint-down(sm){
    width: 100%;
    justify-content: space-between;
    a{
      width: inherit;
    }
  }
}

ssab-cx-pagination-bottom{
  .cx-sorting{
    background-color: $ssab-white;
    padding: 1.5rem;

    @include media-breakpoint-between(xs, sm) {
      padding: 1rem;
      .cx-pagination{
        width: 100%;
      }
    }
  }
}
