html[dir='rtl'] cx-breadcrumb nav span:not(:last-child):after {
  content: '\\';
}

%cx-breadcrumb {
  display: flex;
  flex-direction: column;

  color: currentcolor;
  background-color: var(--cx-color-background);

  padding: var(--cx-spatial-base);

  box-shadow: inset 0px 10px 7px -12px var(--cx-color-dark);

  @include media-breakpoint-up(lg) {
    box-shadow: none;
  }

  @include forFeature('a11yImproveContrast') {
    color: var(--cx-color-text);
  }

  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    text-transform: capitalize;

    // TODO: (CXSPA-6003) Remove feature flags next major
    @include forFeature('a11yTruncatedTextForResponsiveView') {
      @include media-breakpoint-only(xs) {
        white-space: wrap;
        @include type('4');
      }
    }
  }

  nav {
    @include forFeature('a11yImproveContrast') {
      // complements reboot to remove focus-ring from unfocused elements
      &[tabindex='-1']:focus {
        box-shadow: 0 0 0 0 !important;
      }
    }
    padding: 5px 0;
    display: flex;
    justify-content: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding-inline-start: 0;
      margin: 0;

      li {
        display: flex;
        color: var(--cx-color-secondary);

        &:not(:last-child):after {
          content: '/';
        }
        a {
          text-transform: capitalize;
          color: inherit;
          padding: 0px 5px;

          @include forFeature('a11yImproveContrast') {
            color: var(--cx-color-primary);
          }
        }
      }
    }
  }
}
