// Scanner header
scanner-header-main {
  .top-header {
    padding: 3rem 1rem 1.5rem 1rem;
    height: 9rem;

    sup {
      @include ssab-text-sm();
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 4rem;
    background-color: $ssab-gray-lightest;

    > div {
      display: flex;
      align-items: center;
      min-width: 3rem;

      &.product-header {
        flex-direction: column;
        padding: 0 1rem;

        > div {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
          white-space: nowrap;
        }
      }
    }

    .back-button {
      all: unset;
      height: fit-content;
      margin-left: 1rem;
    }

    .link {
      position: relative;
      text-decoration: none;
      color: $ssab-main-color;
      min-height: unset;

      .cart-items {
        @include ssab-text-sm();
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $ssab-attention-red-2;
        color: $ssab-white;
        height: 1.25rem;
        width: 1.25rem;
        position: absolute;
        top: -0.5rem;
        right: 0.75rem;
      }
    }
  }
}
