ssab-cx-minicart {
  .table {
    margin-top: 1.5rem;

    tbody {
      &.no-available-prod {
        max-height: 50vh;
      }

      &:not(.no-available-prod) {
        max-height: 60vh;
      }

      td:last-child {
        padding: unset;
      }
    }
  }

  .text-danger {
    .ssab-icon {
      @include filter-red()
    }
  }

  .popover {
    .popover-body {
      max-height: 95vh;

      .icon-navigation-close {
        width: 24px;
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .popover-body {
      .btn-primary {
        width: 100%;
      }

      .icon-navigation-close {
        right: 18px;
      }
    }
  }
}

ssab-cx-saved-minicart, ssab-cx-minicart {
  @include media-breakpoint-between(xs, sm) {
    @include popoverInMobile();
  }

  a {
    @include media-breakpoint-between(xs, sm) {
      position: relative;
      min-height: unset;
      min-width: unset;
    }
  }

  .table {
    tbody {

      display: block;
      @include addScrollBar();
    }
  }
}

ssab-cx-saved-minicart {
  .no-items {
    padding-top: 1rem;
  }

  .table {
    tbody {
      max-height: 60vh;
    }

    tr {
      td {
        border-top: unset;
        padding: 1.5rem 0.5rem 1.5rem 0rem
      }
    }
  }
}

ssab-cx-collected-items {
  @include ssab-paragraph-md();

  .modal {
    &-body {
      padding: 2rem 0 0 0;

      .table {
        &-responsive {
          max-height: 37vh;
          display: block;
          @include addScrollBar();
        }

        td {
          padding: 1.5rem;
          border: unset;
        }

        thead {
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 10;
          box-shadow: inset 0 0 0, inset 0 -2px 0 $ssab-main-color;

          th {
            text-align: left;
            color: black;
            @include ssab-paragraph-sm();
            font-weight: bold;
          }
        }

        tbody {
          tr {
            &:first-child {
              td {
                border-top: unset;
              }
            }

            border-bottom: 1px solid $ssab-border-color-default;
          }

        }
      }

      .collected-item {
        &-info {
          border-top: 1px solid var(--cx-color-gray-light);
          padding: 1rem 1rem 1rem 0;
          position: relative;

          .ssab-icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &-section {
          border-bottom: 2px solid var(--cx-color-gray-light);
          padding: 1rem;
        }

        &-content {
          position: absolute;
          background-color: white;
          height: 90vh;
          z-index: 10;
          width: 90vw;
          top: 0;
          left: 0;
        }

        &s-mobile {
          max-height: 37vh;
          border-bottom: 1px solid var(--cx-color-gray-light);
          @include addScrollBar();

          .collected-item-info p {
            @include ssab-paragraph-lg()
          }
        }
      }
    }

    &-header {
      border-bottom: unset;
      position: relative;

      .close {
        top: 0;
        right: 0;
        bottom: unset;
        position: absolute;
        font-size: 24px;
      }
    }

    &-content {
      padding: 2rem 4.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    padding: unset;
    .modal {
      &-body {
        .table-responsive {
          max-height: 50vh;
        }
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .modal {
      &-body {
        padding: 1.5rem 0 0 0;

        .collected-items-btn {
          width: 100%;
        }
      }

      &-header {
        padding: 1rem 2rem 1rem 0rem;
      }
    }
  }
}

ssab-cx-minicart-consignment {
  .table {
    &.inner-table {
      margin-bottom: unset;

      tr {
        td {
          padding: unset;
          margin: 6px 0;

          &:first-child {
            border-left: 1px solid var(--cx-color-primary);
            padding-left: 0.75rem;
          }
        }
      }
    }

    &-consignment {
      max-height: 50vh;
      display: block;
      @include addScrollBar();

      hr {
        margin: 0 -2rem;
        border-top: 1px solid $ssab-border-color-default;
        width: calc(100% + 2rem);
      }
    }
  }
}
