ssab-cx-background-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1000;

  cx-media {
    position: absolute;
    height: 430px;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 620px;
      max-height: unset;
      object-fit: cover;
    }

    &.cx-background-image {
      &-materialdata {
        height: 585px;
      }

      &-HOMEPAGE {
        height: 250px;
      }

      &-storeselector {
        height: 250px;
      }

      &-orders {
        height: 405px;
      }

      &-mypreviousorders img {
        object-position: right;
      }

      &-scanner-app {
        height: 9rem;

        img {
          height: 9rem;
        }
      }

      &-search {
        height: 341px;
      }

      &-coilcomparison {
        height: 37rem;
      }

      &-claimrequest {
        height: 24rem;

        img {
          height: 24rem;
        }
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    cx-media {
      height: 330px;
    }
  }
}
