ssab-cx-add-to-cart {
  .pdp-add {
    .units {
      min-width: 90px;
    }

    .add-to-cart {
      @include media-breakpoint-down(md) {
        max-width: unset;
      }
    }
  }

  .units {
    align-self: center;
    min-width: 80px;

    .ng-select.ng-select-single .ng-select-container .ng-value-container {
      padding-inline-start: 0px;

      .ng-value {
        text-overflow: unset;
      }
    }
  }

  .add-to-cart {
    max-width: 270px;
    @include media-breakpoint-between(xs, sm) {
      max-width: unset;
    }
  }

  ssab-cx-bundle-split-toggle {
    display: inline-block;
  }
}
