.btn {
  border-radius: $ssab-button-border-radius;
  border: 0;
  margin: 0;
  padding: 8px 27px;
  height: auto;
  @include ssab-text();

  &-lg {
    padding: 0.8rem 1.8rem;
    line-height: inherit;
  }

  &-link {
    color: var(--cx-color-primary);
    text-underline-offset: 0.5rem;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    @include ssab-H5-14();

    &:hover {
      text-decoration-thickness: 2px;
      color: var(--cx-color-primary);
    }

    &-no-underline {
      @extend .btn-link;
      text-decoration: none;
    }
  }

  &-info {
    background-color: var(--cx-color-primary);
    color: $ssab-white;
    text-transform: uppercase;

    &:hover, &:active {
      background-color: var(--cx-color-accent-1);
      color: var(--cx-color-prmary);
    }

    &:disabled {
      background-color: var(--cx-color-gray-light);
      color: $ssab-white;
    }

    .ssab-icon {
      @include filter-white();
    }
  }

  &-p4 {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  &-primary {
    background-color: var(--cx-color-primary);
    color: $ssab-white;
    text-transform: uppercase;

    &:hover, &:active, &:focus {
      color: $ssab-white;
      background-color: $ssab-main-color-darker !important;
      -webkit-filter: unset !important;
      filter: unset !important;
    }

    .ssab-icon {
      @include filter-white();
    }
  }

  &-secondary {
    text-transform: uppercase;
    background-color: var(--cx-color-inverse);
    color: var(--cx-color-primary);
    border: 1px solid var(--cx-color-primary);

    &:hover, &:active {
      background-color: var(--cx-color-accent-1);
      color: var(--cx-color-primary);
      border: 1px solid var(--cx-color-primary);
    }
  }

  &-tertiary {
    background-color: $ssab-secondary-color;
    color: $ssab-white;
    text-transform: uppercase;

    &:hover, &:active, &:focus {
      color: $ssab-white;
      background-color: $ssab-blue-darker;
    }

    &.btn-block {
      width: 100%;
    }

    .ssab-icon {
      @include filter-white();
    }
  }

  &-download {
    background-color: $ssab-button-download-background;
    text-transform: uppercase;
    color: $ssab-main-color;

    &:hover, &:active, &:focus {
      color: $ssab-main-color;
      background-color: $ssab-blue-lighter;
    }

    &:disabled {
      background-color: var(--cx-color-gray-light);
      color: $ssab-white;
    }
  }

  &-default-height {
    height: $ssab-input-height-default;
  }

  &-flex {
    display: flex;
    align-items: center;

    .ssab-icon {
      flex-shrink: 0;
    }
  }

  &-action {
    &:not(:disabled):not(.disabled):active {
      background-color: $ssab-white;
      border-color: unset;
      color: inherit;

      &:focus {
        box-shadow: unset;
      }
    }

    &:hover {
      border-style: unset;
    }

    &:focus {
      box-shadow: unset;
    }
  }

  &:focus, &.focus {
    box-shadow: none;
  }

  @include media-breakpoint-down(md) {
    @include ssab-H5-14();
  }

  &-icon {
    border-radius: 50%;
    padding: 0.5rem;
    margin: 0.25rem;
    min-width: unset;
    min-height: unset;

    &:hover {
      background-color: var(--cx-color-light);
    }

    &:disabled {
      .ssab-icon {
        @include filter-gray-light();
      }
    }
  }
}

// style adjustments for CMSLinkComponent with .btn class
cx-link {
  &.btn {
    padding: 0;

    > cx-generic-link {

      > a {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        height: $ssab-input-height-default;
        padding: 15px 27px;
        text-decoration: none;

        &:hover, &:active, &:focus {
          color: $ssab-white;
        }
      }
    }
  }
}
