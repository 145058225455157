ssab-cx-information-link {

  .banner{
    &-normal{
      .banner-title, ssab-cx-information-link-column .link-document{
        padding: 0 3.5rem;
      }
    }
    &-title{
      padding-bottom: 3.5rem;
    }
    &-documents{
      display: grid;
      grid-template-columns: 1fr;
    }
    @include media-breakpoint-down(md){
      &-normal{
        .banner-title, ssab-cx-information-link-column .link-document{
          padding: 0 1.5rem;
        }
      }
    }
    @include media-breakpoint-between(xs, sm) {
      &-title{
        padding-bottom: 2.5rem;
      }
      &-normal{
        .banner-title, ssab-cx-information-link-column .link-document{
          padding: 0 1rem;
        }
      }
    }
  }

  .banner-link {
    position: relative;

    img[title] {
      pointer-events: none;
    }

    &-items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      column-gap: 5rem;
      margin: auto;

      &_1 {
        grid-template-columns: 600px 500px 500px;
        ssab-cx-information-link-column{
          max-width: 600px;
        }
        @include media-breakpoint-between(xs, sm) {
          grid-template-columns: unset;
        }
      }

      @include container-max-widths();

      &:not(.banner-link-items_1){
        @include media-breakpoint-down(md) {
          grid-template-columns: repeat(3, 1fr);
          column-gap: 3rem;
          row-gap: 1.5rem;
        }

        @include media-breakpoint-between(xs, sm) {
          grid-template-columns: 1fr;
          text-align: center;
        }
      }
    }

    &:not(.banner-link-media){
      ssab-cx-information-link-column {
        max-width: 270px;
        @include media-breakpoint-between(xs, sm){
          max-width: unset;
        }
      }
    }

    &.banner-link-media {
      min-height: 380px;
      padding: 7rem 0;

      .banner-link-items {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .btn.btn-download {
          min-width: 232px;
        }
      }

      ssab-cx-information-link-column {
        color: white;
      }

      @include media-breakpoint-down(md) {
        min-height: 300px;
        padding: 4.5rem 0;
        .banner-link-items {
          row-gap: 2rem;

          .btn.btn-download {
            display: block;
            width: 100%;
            min-width: unset;
          }

          &_1 .btn.btn-download {
            width: unset;
            min-width: inherit;
          }

        }
      }

      @include media-breakpoint-between(xs, sm) {
        min-height: 468px;
        padding: 2rem 0;
        .banner-link-items_1 {
          .btn.btn-download {
            display: block;
            width: 100%;
            min-width: unset;
          }
        }
      }
    }
  }

  cx-media {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      max-height: unset;
      object-fit: cover;
    }
  }

}

ssab-cx-information-link-column{
  .ssab-icon.icon-system-download{
    height: 50px;
    width: 50px;
  }
  .link-document{
    min-height: 90px;
    &-content{
      position: relative;
    }
  }
}
