ssab-cx-previous-orders {
  .cx-previous-orders {
    padding-bottom: 4rem;

    @include searchFormAndTable();

    &-title {
      padding-top: 2rem;
      padding-bottom: 6rem;
    }

    .previous-orders-entry-out-of-stock {
      text-align: left;
      margin-right: 41px;
    }

    .cx-reorder-button {
      padding-right: 3rem;
    }

    &-table {
      tr {
        .previous-orders {
          &-table {
            padding: 2.5rem 4rem;
            @include media-breakpoint-down(md) {
              padding: 1.5rem;
            }
            @include media-breakpoint-down(sm) {
              padding: 1rem;
              .table-entries {
                max-width: 92vw;
                position: sticky;
                top: 0;
                left: 1rem;
              }
              .btn-primary.btn {
                width: 100%;
              }
            }
          }

          &-entry {
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            border-bottom: 1px solid $ssab-gray-table;
          }
        }
      }

      ssab-cx-add-to-cart {
        .add-to-cart.col {
          padding: unset;
        }
      }
    }
  }
}

ssab-cx-previous-orders-entry {
  .previous-orders-entry-out-of-stock {
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      text-align: right;
    }
  }

  .previous-orders-entry-text-split {
    @include media-breakpoint-up(md) {
      padding-bottom: 2.5rem;
    }
    @include media-breakpoint-between(sm, md) {
      padding-bottom: 1.5rem;
    }
  }

  ssab-cx-add-to-cart .add-to-cart,
  .previous-orders-entry-out-of-stock {
    width: 215px;

    @include media-breakpoint-down(md) {
      width: 160px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  cx-media img, cx-media.is-missing {
    height: 80px;
  }
}
