#cdc-login-container, #cdc-profile-update-container {
  .gigya-screen {
    * {
      font-family: $ssab-font-family-base;
      @include ssab-paragraph();

      &:not(input) {
        color: $ssab-text-color;
      }
    }

    &.portrait {
      width: 100%;
      padding-left: unset;
      padding-right: unset;
    }

    h2 {
      color: var(--cx-color-primary);
      @include ssab-H2-36();
      @include media-breakpoint-between(xs, sm) {
        @include ssab-H2-32();
      }
    }

    input {
      border-radius: unset;

      &[type=submit], &[type=button] {
        @include ssab-text();
        text-transform: uppercase;
        background-color: var(--cx-color-primary);
        border-color: var(--cx-color-primary);
      }

      &[type=checkbox] {
        width: 1.3rem;
        height: 1.35rem;
        outline: none;
        content: none;
        margin: 0px;

        &:before {
          content: "✓";
          color: transparent;
          flex-shrink: 0;
          font-size: 1rem;
          font-weight: bold;
          width: 1.3rem;
          height: 1.35rem;
          border-radius: 0.2rem;
          border: 2px solid var(--cx-color-primary);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-inline-end: 1rem;
        }

        &:checked {
          &:before {
            background-color: var(--cx-color-primary);
            border-color: var(--cx-color-primary);
            color: var(--cx-color-inverse);
          }
        }
      }
    }

    span.gigya-label-text {
      white-space: nowrap;
    }

    a, a:link {
      color: var(--cx-color-primary);
    }

    .gigya-composite-control.gigya-composite-control-checkbox {
      .gigya-label {
        margin-left: 2rem;
      }
    }
  }
}

#cdc-profile-update-container {
  max-width: 60%;
  margin: auto;
  padding: 0 2rem;

  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding: unset;
  }
}

#cdc-login-container{
  .gigya-screen{
    padding-bottom: unset;
  }
}

//background-color: var(--cx-color-primary);
//border-color: var(--cx-color-primary);
//color: var(--cx-color-inverse);
