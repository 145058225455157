ssab-cx-documents-search {
  .table-responsive {
    @include addScrollBar();
    overflow-y: hidden;

    .th-word-break {
      text-align: start;
      min-width: 10.5rem;
      white-space: normal;
    }
  }


  .table-responsive {
    @include addScrollBar();
    overflow-y: hidden;

    .th-word-break {
      text-align: start;
      min-width: 10.5rem;
      white-space: normal;
    }
  }


  ssab-cx-certificates {
    .cx-documents-table {
      min-width: $ssab-table-width;
    }
  }

  .cx-documents {
    &-tab {
      color: $ssab-white;
      padding-bottom: 1.5rem;

      h3 {
        padding-right: 4rem;
        font-weight: initial;
        cursor: pointer;

        &.selected {
          text-decoration: underline;
          text-underline-offset: 8px;
          text-decoration-thickness: 2px;
        }
      }
    }

    &-table {
      min-width: $ssab-table-width-sm;

      thead {
        background-color: var(--cx-color-primary);

        th {
          color: $ssab-white;
          text-align: left;
          white-space: nowrap;
          font-weight: normal;
          padding: 1.5rem 1rem;
        }
      }

      tbody {
        background-color: $ssab-white;

        td {
          padding: 1rem;

          &:not(.truncate) {
            white-space: nowrap;
          }

          &.truncate {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 1px;
          }
        }
      }
    }

    &-data,
    &-sorting,
    &-download {
      background-color: $ssab-white;
    }

    &-sorting {
      padding: 1.5rem;
    }

    &-download {
      border-top: 1px solid $ssab-border-color-default;
      width: 100%;
      background-color: $ssab-white;
      padding: 1.5rem 0.5rem;

      &.is-sticky {
        position: sticky;
        bottom: 0;
      }
    }

    &-search {
      color: $ssab-white;

      &-option {
        @include wideOption();

        .icon-system-info.icon-label {
          @include filter-white();
        }
      }

      .option-header {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {

    ssab-cx-invoices {
      .cx-documents-table {
        min-width: $ssab-table-width-xs;
      }
    }

    .cx-documents {
      &-tab {
        @include tabTabletAndMobile();

        display: flex;
        flex-direction: row;

        h3 {
          padding-right: 2rem;
          order: 2;

          &.selected {
            font-weight: bold;
            order: 1;
          }
        }
      }

      &-search {
        background-color: $ssab-white;
        color: var(--cx-color-text);
        margin: unset;

        .inner {
          top: 0;
          left: 0;
          height: auto;
          width: 100%;
          transition: background-color 0.3s ease-in-out;
          align-items: center;
          background-color: $ssab-white;
          z-index: 11;
          position: absolute;
          border: 1px solid var(--cx-color-gray-light);
          margin: unset;

          .content {
            height: 96vh;
            padding-top: 2rem !important;
          }
        }

        &-option {
          padding-top: 1rem;
          padding-bottom: 1rem;
          margin-bottom: unset;
          border-top: 1px solid var(--cx-color-gray-light);

          .ssab-input {
            border: unset;
          }

          .icon-system-info.icon-label {
            filter: unset;
          }
        }

        &-select {
          @include selectTabletAndMobile();
        }
      }

      &-instructions {
        padding: 1rem;
      }
    }
    @include datePickerTabletAndMobile();
  }
}
