ssab-filter {
  @extend %ssab-cx-searchbox;

  border: unset;

  .icon-navigation-search{
    margin: 0 0.5rem 0 0.75rem;
  }

  label{
    &.searchbox{
      padding-inline-start: unset;
    }
    &.dirty input{
      margin-left: 1rem;
    }
    input{
      width: calc(100% - 30px);
    }
  }

  &.filter{
    &-gray label{
      border: 1px solid rgba(0, 0, 0, 0.26);
    }
  }

  @include media-breakpoint-between(xs, sm) {
    label{
      cx-icon{
        &.reset{
          left: unset;
          top: unset;
        }
      }
      input{
        background: none;
        height: 35px;
        position: relative;
        padding: unset;
        top: unset;
      }
      &.dirty{
        cx-icon.icon-navigation-search{
          display: none;
        }
      }
    }
  }
}
