.modal{
  .ssab-modal{
    height: 100vh;
    &-md{
      .modal-dialog{
        max-width: $ssab-modal-width-desktop-md;
      }
    }

    &-lg{
      .modal-dialog{
        max-width: $ssab-modal-width-desktop-lg;
      }
    }

    &-xl {
      .modal {
        &-dialog {
          max-width: $ssab-modal-width-desktop-xl;
          min-height: calc(100% - 10rem);
          margin: 5rem auto;
        }

        &-content {
          border: 1px solid $ssab-gray-light;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      &-xl {
        .modal {
          &-dialog {
            max-width: calc(100% - 10rem);
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      &-xl {
        .modal {
          &-dialog {
            margin: unset;
            min-width: 100%;
          }

          &-content {
            border: unset;
            height: 100vh;
          }
        }
      }
      &-md{
        .modal-dialog{
          max-width: $ssab-modal-width-tablet-md;
        }
      }
    }
    @include media-breakpoint-between(xs, sm) {
      &-xl {
        background-color: white;
      }

      &-md{
        .modal-dialog-centered{
          height: auto;
          min-height: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 1rem !important;
          min-width: calc(100% - 2rem) !important;
        }
      }
    }
  }
  &.cx-spinner{
    position: fixed;
    max-width: 100vw;
    max-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    &.show{
      .modal-dialog{
        transform: translate(-50%, -50%); // to get spinner centered
      }
    }
    .modal-dialog{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: unset;
    }
  }
  &.show {
    display: block;
  }
  &.cart-notification{
    height: fit-content;
    top:auto;
    bottom:0;
    -webkit-box-shadow: 0 3px 20px var(--cx-color-gray);
    -moz-box-shadow: 0 3px 20px var(--cx-color-gray);
    box-shadow: 0 3px 20px var(--cx-color-gray);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    .modal-dialog{
      margin: unset;
      max-width:100%;
    }
  }
  &-content {
    padding: 1.5rem;

    .buttons {
      text-align: center;
      button {
        display: inline-block;
      }
    }
  }
}
