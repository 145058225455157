ssab-cx-material-data {
  .cx-material {
    &-data {
      &-search {
        .cx-page-header-title {
          padding-bottom: 2rem;
        }
      }

      &-filter {
        background-color: $ssab-white;
      }

      @include searchFormAndTable();
    }

    &-search {
      &-option {
        @include wideOption();
      }
    }
  }
}
