h1{
  @include ssab-H1-70();
  @include media-breakpoint-between(xs, sm) {
    @include ssab-H1-24();
  }
}

h2{
  @include ssab-H2-36();
  @include media-breakpoint-between(xs, sm)  {
    @include ssab-H2-32();
  }
}

h3, .h3{
  @include ssab-H3-24();
}

h4, .h4{
  @include ssab-H4-18();
}

h5, .h5{
  @include ssab-H5-14();
  @include media-breakpoint-between(xs, sm)  {
    @include ssab-H5-14-min();
  }
}

h6{
  @include ssab-H6-12();
}

h7{
  @include ssab-H7-12();
  @include media-breakpoint-between(xs, sm)  {
    @include ssab-H7-12-min();
  }
}

label{
  @include ssab-label-md();
  &.text-lg{
    @include ssab-label-lg();
    @include media-breakpoint-between(xs, sm)  {
      font-size: 16px;
    }
  }
}
