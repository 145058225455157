@mixin visible-focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
  @include forFeature('a11yImproveContrast') {
    outline-offset: 2px;
    box-shadow: 0 0 0 2px var(--cx-color-inverse);
  }
  // TODO: (CXSPA-7208) Remove feature flag next major
  @include forFeature('a11yVisibleFocusOverflows') {
    z-index: 1000;
  }

  // TODO: (CXSPA-5912) Remove feature flag next major
  @include forFeature('a11yMobileVisibleFocus') {
    @include media-breakpoint-down(md) {
      outline-offset: -2px;
      .ng-select-container {
        position: initial;
      }
      &[type='radio'] {
        outline-offset: 2px;
      }
      & > cx-media {
        transition: none;
        padding: 2px;
      }
    }
  }
}
