body {
  font-family: $ssab-font-family-base;
  color: var(--cx-color-text);
  background-color: $ssab-white;
  position: relative;
  min-height: 100vh;
  @include ssab-paragraph();

  &.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;

    main {
      min-height: 96vh;
    }
  }

  main {
    position: relative;
    min-height: 60vh;
  }

  &.searchbox-is-active.has-searchbox-results header, header {
    position: sticky;
    top: 0;
    z-index: 1055;
  }

  &.modal-open {
    header {
      z-index: 1000;
    }

    ssab-cx-global-message {
      top: 0;
      z-index: 1055;
      position: absolute;
      width: 100%;
    }
  }

}

.popover {
  z-index: 10;

  &-body {
    position: relative;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid var(--cx-color-gray-light);
    background-color: var(--cx-color-inverse);
  }

  &-hamburger-menu {
    z-index: 1055;
  }

  &-xs {
    max-width: 400px;
    min-width: 300px;

    &-max-width {
      max-width: 200px;
    }
  }

  &-sm {
    max-width: 600px;
    min-width: 400px;
  }

  &-md {
    max-width: 800px;
    min-width: 600px;
  }

  &-lg {
    max-width: 800px;
    min-width: 700px;
  }

  &-fix-content {
    width: fit-content;
  }

  &-responsive {
    max-width: 400px;
    min-width: 300px;

    @include media-breakpoint-up(sm) {
      max-width: 600px;
      min-width: 400px;
    }

    @include media-breakpoint-up(md) {
      max-width: 700px;
      min-width: 600px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 800px;
      min-width: 700px;
    }
  }

  &-info {
    left: -24px !important;

    .popover-body {
      white-space: normal;
      border-radius: unset;
      filter: drop-shadow($ssab-template-drop-shadow);
    }
  }

  @include media-breakpoint-between(xs, sm) {
    z-index: 1003;
    min-width: 100%;
    transform: unset !important;
    &-info {
      left: 0px !important;
    }
    &-body {
      padding: 1rem 2rem 1rem 0.5rem;

      .icon-navigation-close {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }
}

.bs-popover-bottom-right {
  .arrow::before, .arrow::after {
    right: 1.5rem;
  }
}

#ssab-spinner {
  z-index: 9999999999;
}

.col-no {
  &-padding {
    padding-left: unset;
    padding-right: unset;
  }

  &-margin {
    margin-left: unset;
    margin-right: unset;
  }
}

a {
  cursor: pointer;
  color: var(--cx-color-primary);
}

.vertical {
  &-align-item {
    display: flex;
    justify-content: center;
    align-items: center;

    &-end {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &-50 {
    vertical-align: 50%;
  }
}

ngb-modal-window {
  .modal {
    &-content {
      padding: $ssab-padding-margin-default * 2;
    }
  }
}

.no-margin {
  margin: unset;
}

.form-control {
  &.is-invalid {
    background-image: unset;
  }
}

.divs-same-height {
  &.row {
    display: flex;
    align-items: stretch;

    [class*="col-"] {
      flex: 1;
      height: auto;
    }
  }
}

.cx-page-header {
  background-color: unset;
  text-align: left;

  &-title {
    padding-bottom: 3.5rem;
    padding-top: 3.5rem;
    @include media-breakpoint-only(md) {
      padding: 3.5rem 1.5rem;
    }
    @include media-breakpoint-between(xs, sm) {
      padding: 2rem 1rem;
    }
  }
}

.empty-data-div {
  width: 100%;
  min-height: 200px;
  background-color: $ssab-white;
  @extend .vertical-align-item;

  &-modal {
    width: 100%;
    min-height: 300px;
    background-color: $ssab-white;
  }
}

.table {
  color: black;

  thead {
    height: $ssab-table-thead-height;

    th {
      @include ssab-text();
      text-align: left;
      vertical-align: middle;

      .ssab-icon.icon-system-info {
        margin-bottom: 3px;
      }

      &.is-sortable {
        position: relative;

        .cx-sorting {
          position: absolute;
          top: 35%;
          height: 32px;
          width: 16px;
          right: 0;

          .icon-sortable-down {
            position: absolute;
            right: 1rem;

            &:not(.icon-rotate-180) {
              margin-top: 12px;
            }
          }
        }

      }
    }
  }

  tr {
    &:not(.no-border) {
      td {
        border-bottom: 1px solid $ssab-border-color-default;
        border-top: unset;
      }
    }

    &:first-child td {
      border-top: 1px solid $ssab-border-color-default;
    }
  }

  &.no-border {
    tr td {
      border-bottom: unset;
      border-top: unset;
    }
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.label-divider {
  display: flex;
  flex-direction: row;

  &:before, &:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid;
    margin: auto;
  }

  &:before {
    margin-right: 10px
  }

  &:after {
    margin-left: 10px
  }
}


.break-line {
  &-w-180 {
    width: 180px;
    white-space: normal !important;
  }

  &-maxw-100 {
    max-width: 100px;
    white-space: normal !important;
  }

  &-minw-180 {
    min-width: 180px;
    white-space: normal !important;
  }
}

.pr {
  &-6 {
    padding-right: 4rem;
  }
}
