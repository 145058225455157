scanner-home,
app-how-to-scan {
  .alert {
    align-items: center;
    color: var(--cx-color-inverse);
    min-height: 50px;
    padding: 0 1rem;
    margin: 0;

    &-info {
      background-color: var(--cx-color-info);
    }

    .ssab-icon {
      @include filter-white();
    }

    .container {
      @include ssab-paragraph();
      padding: 1rem 0;
    }
  }
}

app-qrscanner {
  .canvas {
    display: none;
  }

  .rotation-warning {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $ssab-warning-yellow;
    color: $ssab-text-color;
    text-wrap: unset;
    padding: 0.5rem;
    z-index: 9999;

    &.hidden {
      display: none;
    }
  }

  .camera-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .camera-div {
      height: 9rem;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(md) {
        display: inline;
        display: flex;
        align-items: center;
      }

      .camera {
        margin: 0;
        object-fit: cover;
        width: 100%;

        @include media-breakpoint-down(md) {
          height: 9rem;
          width: 100%;
          display: inline;
        }
      }
    }

    .overlay-element {
      position: absolute;
      top: 0;
      width: 100%;
      background: rgba(30, 30, 30, 0.5);
      -webkit-clip-path: polygon(
          0% 0%,
          0% 100%,
          0% 100%,
          20% 20%,
          80% 80%,
          80% 80%,
          80% 80%,
          80% 100%,
          100% 100%,
          100% 0%
      );
      clip-path: polygon(
          0% 0%,
          0% 100%,
          20% 100%,
          20% 20%,
          80% 20%,
          80% 80%,
          20% 80%,
          20% 100%,
          100% 100%,
          100% 0%
      );
    }


    .laser {
      width: 80%;
      background-color: tomato;
      height: 0.1rem;
      position: absolute;
      top: 25%;
      z-index: 2;
      box-shadow: 0 0 0.25rem $ssab-attention-red;
      -webkit-animation: scanning 3s infinite;
      animation: scanning 3s infinite;
    }


    @-webkit-keyframes scanning {
      50% {
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
      }
    }

    @keyframes scanning {
      50% {
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
      }
    }
  }
}

app-search-input {
  .search {
    display: flex;
    justify-content: center;
    width: 100%;

    &.has-error {
      input {
        border-color: var(--cx-color-danger);
      }
    }

    input {
      @include ssab-paragraph();
      width: 65%;
    }

    button {
      width: 35%;
    }
  }

  .search-error {
    @include ssab-paragraph();
    color: var(--cx-color-danger);
    padding: 0.25rem 0;
  }
}

app-product-list,
app-cart-table {
  .no-product-div {
    margin: 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

app-product-card {
  .product-card-header {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 1rem;
    padding: 1rem;
    background-color: $ssab-gray-lightest;
    border-top: 1px solid $ssab-gray-table;
  }

  .data-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 2rem 0;

    .data-image {
      width: 8rem;
    }

    .data-details {
      max-width: calc(100% - 8rem);
    }
  }

  .data-certificate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: $ssab-gray-lightest;
    margin: 0.5rem 1rem;
  }
}

app-email-dialog,
app-dropdown-menu,
app-how-to-scan {
  @include ssab-paragraph-md();

  .modal-header {
    border-bottom: 0;
    margin-bottom: 2rem;
  }

  .modal-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    padding: 0;

    hr {
      width: 100%;
      margin-bottom: 0;
    }

    ul {
      padding-left: 1rem;
    }

    .search {
      display: flex;
      justify-content: center;
      width: 100%;

      ssab-cx-input {
        width: 70%;
      }

      button {
        width: 30%;
      }
    }

    .email-item {
      display: inline-block;
      background-color: $ssab-gray-lightest;
      border: 1px solid $ssab-gray-table;
      border-radius: 5px;
      padding: 0.25rem 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .accordion {
      width: 100%;

      .accordion-item {
        border-bottom: 1px solid $ssab-gray-table;
      }

      .accordion-header {
        &.collapsed {
          .accordion-button {
            .ssab-icon {
              &.icon-navigation-minus-small {
                display: none;
              }

              &.icon-navigation-plus-small {
                display: inline-block;
              }
            }
          }
        }

        .accordion-button {
          @include ssab-H5-14-min();
          color: $ssab-main-color;
          background: none;
          border: 0;
          padding: 0;
          margin: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 3.5rem;

          .ssab-icon {
            &.icon-navigation-minus-small {
              display: inline-block;
            }

            &.icon-navigation-plus-small {
              display: none;
            }
          }
        }
      }

      .accordion-body {
        padding-bottom: 1rem;

        h4,
        p {
          margin-bottom: 1rem;
        }

        img {
          width: 100%;
          margin-bottom: 1rem;

          &[src=""] {
            display: none;
          }
        }

        .ssab-icon {
          @extend .icon-raahe-works;
          background-size: 32px 32px;
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

app-ocr-initialize {
  .lds-ripple {
    display: inline-block;
    position: absolute;
    top: 7rem;
    right: 8.5rem;
    width: 5rem;
    height: 5rem;
  }

  .lds-ripple div {
    position: absolute;
    border: 4px solid $ssab-white;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
}
