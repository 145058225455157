@media all {
  header {
    color: black;
    background: $ssab-white;
    background-color: $ssab-white;
    display: block;

    #header-area {
      position: relative;

      #testParagraph {
        text-align: right;
        color: $ssab-attention-red;
        font-weight: bold;
        position: absolute;
        bottom: -0.5rem;
        right: -2rem;
        margin-bottom: 0;
      }
    }

    &.is-expanded {
      .navigation {
        position: absolute;
        height: 100vh;
        width: auto;
        background: $ssab-white;
        top: 2px;
        z-index: 1060;
      }

      .PreHeader {
        z-index: 5;
      }

      &::after {
        background-color: $ssab-gray;
        opacity: 0.5;
        content: "";
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
      }
    }

    .header {
      @include container-max-widths();

      padding: 13px 10px;
      display: flex;


      > * {
        flex: auto;
      }

      .navigation-icon {
        &:hover {
          @include media-breakpoint-up(md) {
            @include filter-secondary-color();
          }
        }

        &:active {
          @include media-breakpoint-up(md) {
            @include filter-accent4-color();
          }
        }
      }

      .is-open:focus-within .navigation-icon,
      .navigation-icon-active {
        &,
        &:link,
        &:hover,
        &:active {
          @include media-breakpoint-up(md) {
            @include filter-secondary-color();
          }
        }
      }
    }

    .NavigationBar {
      min-height: 0;
      background-color: inherit;

      .flyout {
        // Show dropdowns via focus only once a nav is clicked
        &:focus-within {
          > nav {
            &:focus,
            &.is-open:focus-within {
              .heading-wrapper {
                background-color: $ssab-gray-lightest;
                border-left: 5px solid var(--cx-color-primary);

                cx-icon {
                  transform: rotate(180deg);
                }

                h4,
                a {
                  margin-left: -5px;
                }
              }
            }
          }
        }

        nav {
          display: flex;

          .wrapper {
            border: 0;
            min-width: 275px;
            box-shadow: $ssab-nav-box-shadow-wrapper;
          }
        }

        h4 {
          margin-bottom: unset;
          gap: 8px;

          div {
            flex-grow: 1;
            white-space: normal;
            word-break: break-word;
          }

          cx-icon {
            float: right;
            line-height: normal;
          }
        }
      }
    }

    .TopNavigationLinks {
      width: min-content;

      .flyout {
        nav {
          .heading-wrapper {
            .heading-open {
              background-color: $ssab-gray-lightest;
              border-left: 5px solid var(--cx-color-primary);
              padding-left: 25px;
            }
          }
        }

        cx-generic-link {
          border-bottom: unset;

          // prevent double icon on hamburger menu navigation links
          &.icon-after {
            &:after {
              display: none;
            }

            > a {
              &.icon-after {
                > div {
                  display: inline-block;
                  vertical-align: middle;
                }

                &:after {
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }

          a {
            white-space: normal;
          }
        }
      }
    }

    .PreHeader {
      height: 22px;
      width: 22px;
      flex: none;
      position: relative;

      @include media-breakpoint-up(md) {
        margin-left: 10px;
        height: 40px;
        width: 40px;
      }

      ssab-cx-hamburger-menu {
        .ssab-icon {
          cursor: pointer;
          position: absolute;
          background-size: 34px 34px;
          height: 34px;
          width: 34px;
          top: -9px;
          left: -6px;
          right: -6px;

          @include media-breakpoint-up(md) {
            background-size: 56px 56px;
            height: 56px;
            width: 56px;
            top: -16px;
            left: -8px;
            right: -8px;
          }
        }

        .menu-text {
          pointer-events: none;
          position: absolute;
          display: block;
          width: 100%;
          bottom: -10px;
          font-size: 8px;

          @include media-breakpoint-up(md) {
            bottom: -6px;
            font-size: 12px;
          }
        }
      }
    }

    .SiteLogo {
      margin: 0 50px;
      flex: none;
      position: relative;
      height: 22px;

      @include media-breakpoint-up(md) {
        height: 40px;
        width: 120px;
      }
    }

    .HeaderLinks {
      max-width: min-content;

      ssab-cx-navigation {
        display: inline-block;
        line-height: 0;
      }

      ssab-cx-navigation-ui {
        display: inline-block;

        .wrapper {
          border: 0;
          position: absolute;
          padding: 0;
          padding-top: 10px;
          display: none;
          background-color: $ssab-white;
          min-width: 160px;
          height: auto;
          z-index: 3;

          .childs {
            h4 {
              padding: 2rem 1rem;
              border-bottom: 1px solid var(--cx-color-light);
              margin: unset;
            }

            a {
              padding: 2rem 1rem;
              border-bottom: 1px solid var(--cx-color-light);

              &:hover {
                color: var(--cx-color-text);
                background-color: $ssab-gray-lightest;
                margin: 0;
                box-shadow: $ssab-nav-box-shadow;
                z-index: 3;
              }
            }
          }
        }
      }
    }

    .SearchBox {
      padding: 0 4rem;
    }

    .SiteContext {
      flex: none;
      display: flex;
      background-color: inherit;

      & > * {
        align-self: center;
      }

      &:after {
        border: 0;
      }

      .Currency {
        color: $ssab-main-color;
        font-weight: bold;
      }

      .Language {
        position: relative;
        height: 28px;
        width: 28px;
        color: transparent;
      }

      .context-item-wrapper {
        padding: 0 1rem;
        border: 1px $ssab-border-color-default;

        .context-select {
          margin: 0 auto;
          cursor: pointer;
          text-align: center;
          font-size: 18px;
        }

        .context-options-container {
          position: absolute;
          cursor: pointer;
          min-width: 86px;
          min-height: 44px;
          max-width: 200px;
          padding-top: 10px;
          display: none;
          border-radius: 2px;
          z-index: 3;
        }

        .context-select-option {
          display: none;
          padding: 10px 8px;
          border: 1px solid $ssab-gray-lighter;
          color: var(--cx-color-primary);
          margin: 0 2px;
          background: $ssab-white;
          text-transform: uppercase;

          &:hover {
            background-color: $ssab-gray-lightest;
            box-shadow: $ssab-nav-box-shadow;
          }
        }

        .content-select-visible {
          display: block;
        }
      }
    }

    .SiteLogin {
      padding: 0;
      background: inherit;
      flex: none;

      label {
        margin: 0;
        position: relative;
      }

      nav {
        h5 {
          margin: 0;
          padding: 0 1rem;
        }
      }
    }

    .MiniCart {
      display: flex;
      max-width: fit-content;
      justify-content: space-around;

      & > * {
        flex: none;
        align-self: center;
      }

      &:after {
        border: 0;
      }

      .count {
        align-self: center;
        position: relative;
        right: 5px;
        bottom: 15px;
        border: 1px solid #0064ff2b;
        border-radius: 50%;
        width: 22px;
        z-index: 3;
        background: #e33c2b;
        color: $ssab-white;
        height: 22px;
        font-size: 11px;
        text-align: center;
      }

      .popover {
        margin-top: 5px;
      }

      ssab-cx-minicart,
      ssab-cx-saved-minicart {
        margin: 0 1rem;

        > a {
          background-color: inherit;
          display: flex;
          cursor: pointer;
        }
      }
    }

    %cx-searchbox {
      .searchbox {
        background-color: inherit;
      }

      %cx-icon {
        position: absolute;
        margin: 0 10px;
      }

      label {
        padding: 0 5px;
      }

      input {
        margin-left: 40px;
      }
    }

    .SearchUnits {
      position: relative;
      max-width: 220px;

      ng-select.ng-select {
        position: initial;
      }
    }

    @include media-breakpoint-only(lg) {
      .SiteLogo {
        margin: 0 0 0 2rem;
      }
      .SearchBox {
        padding: 0 2rem;
      }
      .SearchUnits {
        max-width: 180px;
      }
      .SiteContext {
        .context-item-wrapper {
          margin: 0 0.5rem;
        }
      }
    }

    @include media-breakpoint-only(md) {
      .SiteLogo {
        margin: 0 2rem;
      }
      .SearchBox {
        padding: unset;
      }
      .SiteContext {
        padding: 0 0 0 0.5rem;

        .context-item-wrapper {
          margin: 0;
        }
      }

      .MiniCart {
        min-width: auto;

        ssab-cx-minicart,
        ssab-cx-saved-minicart {
          margin: 0 1rem;
        }
      }

      .HeaderLinks {
        ssab-cx-navigation-ui .wrapper {
          margin-top: 1rem;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .TopNavigationLinks {
        width: inherit;
      }

      .HeaderLinks {
        ssab-cx-navigation-ui {

          &.flyout {
            > nav:hover {
              > .wrapper {
                display: block;
              }
            }
          }

          &.flyout:focus-within {
            > nav {
              &.is-open:focus-within, &:focus {
                > .wrapper {
                  display: block;
                }
              }
            }
          }

          .wrapper {
            height: auto;
            min-width: 300px;
            right: 0;
          }
        }
      }
      .SiteContext {
        .context-item-wrapper {
          margin: 0;
        }
      }
      .SearchUnits {
        &.has-components {
          padding: 1rem 2rem;
        }

        max-width: unset;

        .ng-select .ng-select-container .ng-value-container,
        .ng-select.ng-select-single .ng-select-container .ng-value-container {
          padding-inline-start: unset;
        }
      }

      .NavigationBar {
        .flyout {
          nav {
            display: initial;

            &.is-open {
              .wrapper {
                /*subcategory wrapper shown only on expand button click*/
                display: block;
              }
            }

            .heading-wrapper {
              box-shadow: unset;
              text-transform: unset;
              font-weight: initial;
              border-bottom: unset;
              color: var(--cx-color-primary);

              a {
                padding: 1rem 2rem;
              }
            }

            .wrapper {
              position: inherit;
              height: inherit;
              min-width: auto;
              box-shadow: unset;
              display: none;
            }
          }
        }
      }
    }

    @include media-breakpoint-between(xs, sm) {
      .SearchUnits {
        max-width: unset;
      }

      .ssab-icon {
        width: 20px;
        height: 20px;
      }

      .header {
        padding: 0 1rem;
        flex-wrap: nowrap;
      }

      .SiteLogo {
        margin: 0 0 0 1rem;
        width: 67px;

        ssab-cx-banner {
          a {
            min-height: unset;
            min-width: unset;
          }
        }
      }

      .SiteContext {
        padding: 0;

        .context-item-wrapper {
          min-width: auto;
          padding: 0.5rem;

          .context-select {
            font-size: 14px;
          }
        }

        .Language {
          height: 24px;
          width: 24px;
        }
      }

      .SearchBox {
        padding: 0 1rem;
      }

      .SiteLogin {
        nav h5 {
          padding: 0 0.5rem;
        }

        span {
          background-size: 73% 73%;
          border-bottom: 0px;
        }
      }

      .MiniCart {
        padding-left: unset;
        max-width: fit-content;
        justify-content: space-around;
        flex-wrap: nowrap;

        .count {
          top: -12px;
          border: 0;
          height: 20px;
          position: absolute;
          right: -12px;
          width: 20px;
        }

        ssab-cx-minicart,
        ssab-cx-saved-minicart {
          min-width: unset;
          margin: 0 0.5rem 0 0.5rem;
        }
      }

      .HeaderLinks {
        ssab-cx-navigation-ui .wrapper {
          z-index: 1003;
          width: 100vw;
          margin-top: 10px;

          .childs {
            box-shadow: $ssab-nav-box-shadow-inner;

            a {
              border-bottom: unset;
            }
          }
        }
      }
    }
  }
}

