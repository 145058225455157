ssab-datepicker-input {
  .option-header {
    display: none;
  }

  .dropdown-menu {
    @include ssab-paragraph();
  }

  .ngb-dp-weekday.small {
    @include ssab-paragraph-sm();
    line-height: 2rem;
  }

  .input-group {
    position: relative;
    flex-wrap: nowrap;
    box-sizing: border-box;
    border-bottom: 1px solid var(--cx-color-border-color);
    height: $ssab-input-height-default;
    background-color: var(--cx-color-inverse);

    label.text-lg {
      display: none;
    }

    .input-label {
      display: inline-block;
      width: calc(100% - 80px); // icons calendar & chevron-down
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;

      &.has-value {
        border-right: none;
      }

      input {
        border: unset;
        height: auto;
        padding: 14px 12px 11px;

        &.form-control[readonly] {
          background-color: inherit;
        }
      }

      ngb-datepicker {
        width: 100%;

        @include media-breakpoint-down(sm) {
          right: -37px; // btn-lg min-width 53px - input-label padding-right 16px
        }

        .ngb-dp-months {
          @include media-breakpoint-down(sm) {
            display: block;
          }
        }

        .ngb-dp-month {
          width: 100%;

          &:first-child .ngb-dp-week {
            padding-left: 1rem;
          }

          &:last-child .ngb-dp-week {
            padding-right: 1rem;
          }
        }

        .ngb-dp-week {
          padding-bottom: 0.25rem;
          padding-top: 0.25rem;
          justify-content: space-between;
        }

        .ngb-dp-day,
        .ngb-dp-week-number,
        .ngb-dp-weekday {
          box-sizing: border-box;
          width: 100%;
        }

        .ngb-dp-day {
          &.hidden {
            display: block;
          }
        }
      }
    }

    .input-group-append {
      display: inline-block;
      width: 40px;
      text-align: left;

      &.input-calendar-icon {
        @include media-breakpoint-up(lg) {
          transform: translateY(-2px);
        }
      }

      .ssab-icon {
        cursor: pointer;
      }

      .icon-system-calendar {
        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }
      }

      .icon-chevron-down {
        @include media-breakpoint-up(lg) {
          margin-right: 10px;
        }
      }
    }
  }

  .ngb-dp-arrow-btn {
    margin: 0;
  }

  .np-dp {
    &-weekday {
      width: 46px;
      text-align: center;
    }

    &-weekdays {
      border-bottom: 1px solid var(--cx-color-pink);
    }

    &-day {
      width: 46px;
      border-top: 1px solid var(--cx-color-pink);
      padding: 8px 4px;

      @include media-breakpoint-down(sm) {
        padding: 8px 2px;
      }
    }

    // Weeks row overrides
    &-week {
      background-color: var(--cx-color-white) !important;
      padding: 0;
    }

    &-arrow-btn {
      width: 48px;
      padding: 0;
      margin: 0;
    }

    &-header {
      background-color: var(--cx-color-white);
      border-style: none;
      padding: 0;
    }

    &-month {
      &:first-child .ngb-dp-week {
        padding-left: 0;
      }

      &:last-child .ngb-dp-week {
        padding-right: 0;
      }
    }

    &-arrow {
      width: auto;
      height: auto;
    }

    &-navigation-chevron {
      border-width: .1em .1em 0 0;
      width: 8px;
      height: 8px;
    }
  }

  .bg-light {
    background-color: var(--cx-color-white);
  }

  &.calendar {
    &-lg {
      width: 285px;
    }

    &-md {
      width: 250px;

      .input-label input {
        font-size: 14px;
      }
    }

    &-sm {
      width: 170px;

      .input-label input {
        font-size: 12px;
      }
    }

    &-dark {
      .input-label input::placeholder {
        color: $ssab-text-color !important;
        opacity: 1; /* Firefox */
      }
    }
  }

  @include media-breakpoint-down(md) {
    .input-group {
      border-bottom: unset;

      label.text-lg {
        display: block;
      }

      .input-group-append {
        width: 60px;

        &.input-calendar-icon {
          width: 40px;
          text-align: right;
        }
      }

      .input-label {
        width: calc(100% - 100px);

        ngb-datepicker {
          right: 0;
          left: 0 !important;
          transform: unset !important;
          top: 3rem !important;

          .ngb-dp-month {
            width: 100%;
          }
        }
      }
    }
  }
}
