ssab-cx-product-price {
  .pt-0-important {
    padding-top: 0 !important;
  }
  .customer-specific-price {
    color: $ssab-attention-red;
  }

  .discount-price {
    color: $ssab-success-green;
  }

  .traffic-light {
    position: relative;
    right: 0rem;
  }

  .stock-info-container {
    position: static;
   /* padding-left: 15.5rem;*/
  }

  .stock-line-single {
    vertical-align: middle;
  }

  .cx-price {
    &-plp {
      .cx-price-stock {
        text-align: right;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .cx-price-plp {
      padding: 1.5rem 0 1rem;
      border-top: 1px solid $ssab-border-color-default;

      .cx-price-stock {
        text-align: left;
        padding-bottom: unset;
      }
    }
  }

  @include media-breakpoint-between(xl, xxl) {
    .traffic-light {
      position: absolute;
      right: 5%;
    }

    .stock-info-container {
      position: relative;
    }
  }

  .cx-stock-level {
    &-inStock, &-green {
      .ssab-icon {
        @include filter-green();
      }

      @extend .text-green;
    }

    &-lowStock, &-yellow {
      .ssab-icon {
        @include filter-yellow();
      }

      @extend .text-yellow;
    }
    &-noStock, &-noCustomerStock, &-red, &-outOfStock{
      .ssab-icon{
        @include filter-red();
      }

      @extend .text-red;
    }
  }
}
