ssab-cx-product-list-item {
  background-color: $ssab-product-background;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: 18% 28% 50%;
  grid-template-rows: auto auto;
  column-gap: 2%;
  row-gap: 1rem;

  .item {
    &-image {
      grid-row: 2/span 2;
      grid-column: 1;
      position: relative;

      ssab-cx-add-to-wishlist {
        button.ssab-icon:disabled {
          @include filter-gray();
        }
      }

      .ssab-icon {
        position: absolute;
        top: 0.5rem;
        right: 0rem;
        z-index: 10;
        min-width: unset;
        min-height: unset;
      }
    }

    &-title {
      grid-row: 1;
      grid-column: 1/span 3;

      a {
        color: $ssab-text-color;
      }
    }

    &-attributes {
      grid-column: 2;
      grid-row: 2;
      border-right: 1px solid $ssab-border-color-default;
      padding-right: 0.5rem;
      line-height: 22px;
    }

    .product-classifications {
      color: $ssab-almost-black;
    }

    &-price {
      grid-column: 3;
      grid-row: 2;
    }
  }

  span.label {
    display: inline-block;
    width: 50%;
  }

  .cx-product {
    &-image-container {
      height: 100%;
      position: relative;

      cx-media {
        width: 100%;
      }
    }

    &-name {
      em {
        font-style: inherit;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 22px;
  }

  .quantity, .add-to-cart {
    padding: 0px;
  }

  ssab-cx-add-to-cart {
    .btn {
      height: $ssab-input-height-default;
      padding: 11px 14px;

      &.btn-default-height {
        height: auto;
        min-height: $ssab-input-height-default;
      }
    }
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 22% 28% 46%;
  }

  @include media-breakpoint-between(xs, sm) {
    grid-template-columns: 47% 47%;
    grid-template-rows: auto auto auto;
    column-gap: 3%;
    row-gap: 1.5rem;
    padding: 1.5rem 1rem;
    .item {
      &-image {
        grid-row: 2;
        grid-column: 1;
      }

      &-title {
        grid-column: 1/ span 2;
        grid-row: 1;
      }

      &-attributes {
        grid-column: 2;
        grid-row: 2;
        border-right: unset;
        padding-right: unset;
      }

      &-price {
        grid-column: 1/ span 3;
        grid-row: 3;
      }
    }
  }
}

.comparison-price {
  color: $ssab-gray-light;
}

ssab-cx-product-list {
  .cx-page {
    &-section {
      background-color: var(--cx-color-inverse);
      margin-top: 250px;
      padding: 60px 1.5rem 3rem 3rem;

      @include media-breakpoint-up(xl) {
        min-height: calc(100vh - 780px); // 780 header+footer+section margin+section padding
      }
    }

    &-header {
      position: absolute;
      max-width: var(--cx-page-width-max);
      top: 1rem;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      padding: 3rem 0;
      pointer-events: none;

      h3 {
        text-align: center;
      }
    }
  }

  .cx-sorting {
    &.bottom {
      padding-top: 3rem;
      @include media-breakpoint-up(lg) {
        padding-left: unset;
        padding-right: unset;
      }
    }

    &.top {
      padding-bottom: 2rem;

      cx-sorting ng-select .ng-value {
        padding-right: 5rem;
      }
    }
  }

  @include media-breakpoint-only(md) {
    .cx {
      &-sorting {
        padding: 1.5rem;

        &.bottom {
          padding: 1rem 2rem 2rem;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .cx {
      &-page {
        &-section {
          padding: unset;
          margin-top: unset;
        }

        &-header {
          h3 {
            text-align: left;
          }
        }
      }
    }

  }

  @include media-breakpoint-between(xs, sm) {
    width: 100%;
    .cx {
      &-sorting {
        padding: 1rem;

        &.bottom {
          padding: 2rem 1rem;
        }
      }

      &-page {

        &-header {
          padding: 3rem 1rem 0 1rem;
        }
      }

      &-pagination {
        width: 100%;
        padding-top: 1rem;
      }
    }
  }
}

cx-sorting {
  &.ssab-sorting {
    ng-select {
      .ng-select-container {
        border: unset;
        background-color: transparent;
      }
    }
  }
}
