// Scanner common table styles
app-product-list,
app-cart-table,
app-order,
app-product-card {
  table.table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 0;

      &.first {
        padding-left: 1rem;
      }

      &.last {
        text-align: right;
        padding-right: 1rem;
      }
    }

    th {
      @include ssab-H5-14();
      text-transform: uppercase;
    }

    td {
      @include ssab-text-sm();
      padding: 0.5rem 0.25rem 0.5rem 0;
      word-break: break-word;
    }

    .header {
      text-align: start;
      background-color: $ssab-main-color;
      color: $ssab-white;
      height: 3rem;
      position: -webkit-sticky; /* For Safari/WebKit */
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .content {
      height: 3rem;
      border: 0;
      border-bottom: 1px solid $ssab-gray-table;

      td {
        ul {
          margin: 0;
        }

        button {
          all: unset;
        }
      }
    }
  }
}
