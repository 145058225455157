#onetrust-consent-sdk {
  #onetrust-pc-sdk {
    h2#ot-pc-title {
      color: $ssab-text-color;
    }

    div#ot-pc-desc {
      color: $ssab-text-color;
    }

    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn) {
      &[id^="accept-recommended-btn-handler"], &.save-preference-btn-handler {
        background-color: $ssab-main-color;
        border-color: $ssab-main-color;
        color: $ssab-white;
      }
    }

    .ot-sdk-row h3 {
      color: $ssab-text-color;
    }

    .ot-cat-grp .ot-always-active {
      color: $ssab-main-color;
    }

    .ot-tgl input:checked + .ot-switch .ot-switch-nob {
      background-color: $ssab-main-color;
      border: 1px solid $ssab-main-color;

      &:before {
        background-color: $ssab-white;
        border-color: $ssab-white;
      }

    }
  }

  p#onetrust-policy-text {
    color: $ssab-text-color;
  }

  button#onetrust-accept-btn-handler, button#onetrust-reject-all-handler {
    background-color: $ssab-main-color;
    border-color: $ssab-main-color;
    color: $ssab-white;

  }

  button#onetrust-pc-btn-handler, button#onetrust-pc-btn-handler.cookie-setting-link {
    color: $ssab-main-color;
    border-color: $ssab-main-color;
    background-color: $ssab-white

  }
}
