/*!
  SPARTA v0.1
  This file is for theme configuration. These variables are used in global and component CSS files.

  You can:
    1) Set new values for Bootstrap variables - https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
    2) Set new values for cxbase variables - cxbase/_variables.scss
    3) Set new values for component variables - app/__/_.scss
*/

$font-family-roboto: "Roboto", sans-serif;

$ssab-font-family-base: $font-family-roboto;

$ssab-main-color: #002664;
$ssab-main-color-darker: #001331;
$ssab-almost-black: #2b2c2d;
$ssab-secondary-color: #72B5CC;
$ssab-blue-lightest: #EFF8FC;
$ssab-blue-lighter: #8AC5DA;
$ssab-blue-light: #93D2E8;
$ssab-blue-dark: #589AB1;
$ssab-blue-darker: #11546A;
$ssab-white: #FFFFFF;
$ssab-text-color: #000000;
$ssab-gray: #707070;
$ssab-gray-light: #C8C8C8;
$ssab-gray-lighter: #f2f2f2;
$ssab-gray-lightest: #F2F6F7;
$ssab-gray-shadow: #a7a7a7;
$ssab-product-background: #FAFAFA;
$ssab-almost-white: #F2F1F1;
$ssab-icbin-white: #F8F8F8;
$ssab-success-green: #27A74A;
$ssab-success-green-bg: #3CC03B;
$ssab-success-green-bg-1: #3bc03b40;
$ssab-success-green-bg-2: #164916;
$ssab-attention-red: #FF0000;
$ssab-attention-red-1: #ff00003a;
$ssab-attention-red-2: #de2d2d;
$ssab-info-blue: #3E66FB;
$ssab-border-color-default: #F0F2F3;
$ssab-list-dates-color: #3E66FB;
$ssab-gray-table: #EBEBEB;
$ssab-banner-gray-background: #F3F3F3;
$ssab-alert-notification: #ff9966;
$ssab-delayed-color: #3E66FB;
$ssab-yellow: #FFA700;
$ssab-warning-yellow: #F88C2D;
$ssab-button-download-background: #E5F4F7;


//Status colors on Order History
$ssab-status-received: #FFA700;
$ssab-status-open: #FFA700;
$ssab-status-draft: #707070;
$ssab-status-delivered: #27A74A;
$ssab-status-invoiced: #27A74A;
$ssab-status-closed: #707070;
$ssab-status-blocked: #DC3645;
$ssab-status-cancelled: #C8C8C8;


$ssab-input-height-default: 48px;
$ssab-input-height-mobile: 60px;
$ssab-padding-margin-default: 12.5px;
$ssab-input-dropdown-height: 228px;
$ssab-width-input-stepper: 80px;

$ssab-table-width: 1280px;
$ssab-table-width-sm: 960px;
$ssab-table-width-xs: 780px;
$ssab-table-width-md: 1440px;
$ssab-table-width-lg: 1680px;
$ssab-table-thead-height: 70px;

$ssab-modal-width-desktop-md: 605px;
$ssab-modal-width-tablet-md: 500px;
$ssab-modal-width-desktop-lg: 880px;
$ssab-modal-width-desktop-xl: 1244px;

$ssab-banner-height: 630px;
$ssab-banner-background-color: #E4F4F7;

$theme-colors: (
  // override standard theme colors
  'primary': $ssab-main-color,
  'secondary': $ssab-secondary-color,
  'accent-1': $ssab-blue-lighter,
  'accent-2': $ssab-blue-light,
  'accent-3': $ssab-blue-dark,
  'accent-4': $ssab-blue-darker,
  'accent-5': $ssab-blue-lightest,
  'text': $ssab-text-color,
  'gray': $ssab-gray,
  'gray-light': $ssab-gray-light,
  'almost-white': $ssab-almost-white,
  'icbin-white': $ssab-icbin-white,
  'success': $ssab-success-green,
  'danger': $ssab-attention-red,
  'info': $ssab-info-blue,
  'border-color': $ssab-border-color-default,
  'light': $ssab-gray-lightest
);

$ssab-button-border-radius: 0;
$ssab-nav-box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1);
$ssab-nav-box-shadow-webkit: 0px 2px 2px -1px rgba(0, 0, 0, 0.1);
$ssab-nav-box-shadow-moz: 0px 2px 2px -1px rgba(0, 0, 0, 0.1);
$ssab-nav-box-shadow-wrapper: rgb(25 25 25 / 30%) 0px 0px 50px;
$ssab-nav-box-shadow-inner: inset -5px 10px 20px -5px rgb(0 0 0 / 10%);

$ssab-template-drop-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);

// z-indexes
$zindex-base: 1000;
$zindex-header-nav: $zindex-base + 10;
$zindex-modal-backdrop: $zindex-base + 90 !important; // ngb-modal-backdrop inline style is defaulting to 1050
$zindex-modal: $zindex-base + 100;
$zindex-spinner: $zindex-base + 110;
