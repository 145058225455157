@use "sass:map";

// this template is used on:
// my-account/documents,
// my-account/my-details,
// my-account/orders
// my-account/material-data

cx-page-layout.AccountPageTemplate {
  .BodyContent {
    max-width: 100%;
    padding: 0;
    filter: drop-shadow($ssab-template-drop-shadow);
  }

  .BottomContent, .TopContent {
    align-items: self-start;
  }

  ssab-cx-update-profile .cx-page-header-title {
    padding-top: 2.75rem; //44px
    padding-bottom: 2.4rem; //44px
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      padding-bottom: 3.75rem; //67px
      padding-top: 4.18rem; // 69px
    }

    @include media-breakpoint-up(lg) {
      padding: 3.25rem 1.5rem 5rem; //52px 24px 80px 24px
    }

    @include media-breakpoint-up(xxl) {
      max-width: map.get($container-max-widths, "xxl");
      margin: 0 auto 0.5rem;
    }
  }

  ssab-cx-material-data,
  ssab-cx-documents-search,
  ssab-cx-order-history,
  ssab-cx-previous-orders,
  ssab-cx-coils-search,
  ssab-cx-claim-request-form,
  ssab-cx-claims-search {
    margin-bottom: 3rem;
    @include container-max-widths();

    @include media-breakpoint-up(lg) {
      padding: 0 1.5rem;
    }
  }

  cx-page-slot > ssab-cx-update-profile {
    max-width: unset;
    z-index: 1;

    & + ssab-cx-background-image {
      z-index: 0;

      cx-media {
        height: 130px;

        @include media-breakpoint-up(sm) {
          height: 175px;
        }

        @include media-breakpoint-up(lg) {
          height: 223px;
        }
      }
    }
  }
}
