.order-summary,
.cx-order-items {

  .contact-details, .delivery-details {
    margin-top: 0px;
  }

  ssab-cx-cart-item-list .item-block,
  ssab-cx-cart-totals .header-block {

    border: 0px;
    padding: 0px;
    border-radius: 0px;
  }

  ssab-cx-cart-totals .header-block {
    margin-top: 10px;
    padding-top: 10px;
  }

  .order-totals {
    @include media-breakpoint-down(sm) {
      padding: 1rem 0;
    }
  }

  @include media-breakpoint-down(md) {
    .divs-same-height{
      &.row{
        [class*="col-"]{
          flex:unset;
        }
      }
    }
  }
}

ssab-cx-cart-header ssab-cx-cart-totals .order-totals {
  @include media-breakpoint-down(sm) {
    padding: 1rem;
  }
}

