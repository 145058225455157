@import 'fonts';
@import "variables";

:root {
  @each $color, $value in $theme-colors {
    --cx-color-#{$color}: #{$value};
  }

  --cx-page-width-max: 1420px;
}
