ssab-cx-item-stepper {
  .cx-counter{
    height: $ssab-input-height-default;
    display: flex;
    justify-content: center;
    &-action{
      display: inline-block;
      margin: unset;
      .btn{
        width: 20px;
        min-width: 20px;
        height: 100%;
        border: unset;
        padding: unset;
      }
    }
    &-stepper{
      display: inline-block;
      input{
        max-width: $ssab-width-input-stepper;
        border-radius: unset;
        text-align: center;
        border-color: var(--cx-color-primary);
        margin-bottom: unset;
        background-color: transparent;
        &.form-control{
          padding: 0rem;
        }
      }
    }
  }
  .icon-navigation{
    &-minus-small{
      margin-right: 0.5rem;
    }
    &-plus-small{
      margin-left: 0.5rem;
    }
  }
}
