ssab-cx-login-form,
ssab-cx-forgot-password {
  padding: 1.5rem;
  background-color: var(--cx-color-inverse);

  .img-logo {
    max-width: 150px;
  }
}

ssab-cx-login-form{
  &.user-form{
    display: initial;
  }
}

ssab-cx-login {
  ssab-cx-navigation-ui{
    &.flyout.is-open .wrapper {
      display: block;
    }
    .wrapper{
      display: none;
    }
  }
}

ssab-cx-update-profile .cx-my-details {
  background-color: $ssab-white;
  padding: 2rem 1rem;
  width: 100%;

  .BottomContent{
    cx-generic-link a{
      @extend .text-underline-blue;
      @extend .text-primary;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 3rem 0;
  }

  .user-details {
    @include container-max-widths();
    @include media-breakpoint-up(md) {
      padding: 0 1.5rem;
    }

    ssab-cx-show-inches-toggle{
      margin-top: 1rem;
    }
  }

  p {
    margin-bottom: 0.75rem;
  }
}

ssab-cx-store-selector {
  .cx-page-header-title {
    padding-top: 5.5rem;
    padding-bottom: 5rem;
  }

  .cx-stores {
    section {
      width: 60%;

      ul {
        list-style-type: none;
        padding-left: unset;
        text-transform: uppercase;
        color: var(--cx-color-primary);

        li {
          padding-bottom: 2rem;
          cursor: pointer;
        }
      }
    }
  }
}

ssab-cx-site-context-selector {
  .context-item-wrapper {
    .content-select-visible {
      @include ssab-paragraph();

      background-color: $ssab-white;
      color: var(--cx-color-text);
      padding: 0.75rem;

      .cx-multiple-stores {
        padding-top: 1rem;
        border-top: 1px solid $ssab-border-color-default;
        margin-top: 1.5rem;
      }

      .context-select-option {
        border: unset;
        color: var(--cx-color-text);

        &:hover {
          background-color: unset;
          box-shadow: unset;
          -webkit-box-shadow: unset;
        }
      }

      &.site-language {
        margin-top: 1.5rem;
        border: 1px solid $ssab-border-color-default;
        margin-left: -2rem;
        cursor: unset;
        min-width: 200px;
        max-height: 75vh;
        overflow-y: visible;
        overflow-x: hidden;

        .context-select-option:hover{
          background-color: $ssab-gray-lightest;
        }

        .active-language{
          padding-right: 2rem;
          &::after {
            color: var(--cx-color-text);
            position: absolute;
            content: "";
            display: inline-block;
            height: 8px;
            width: 18px;
            top: 0px;
            right: 0px;
            border-width: 0 0 2px 2px;
            border-style: solid;
            border-color: var(--cx-color-text);
            transform: rotate(-45deg);
            margin-left: 2rem;
          }
        }

        @include media-breakpoint-down(sm) {
          min-width: 150px;
          margin-left: -5rem;
          margin-top: 0.75rem;
        }
      }
    }
  }

}
