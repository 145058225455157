.grid {
  display: flex;
  margin: 25px 0;
  max-width: 1920px;
  flex-wrap: wrap;

  &-vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &-100 {
    width: 100%;
  }

  @include media-breakpoint-between(xs, sm) {
    margin: unset;
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 25px);
  max-width: calc(100% - 25px);
  margin: 0 12.5px;

  &-100 {
    flex-basis: calc(100% - 25px);
    max-width: calc(100% - 25px);
  }
}

.main-top {
  position: relative;
}

.message.error {
  color: $ssab-attention-red;
}


cx-generic-link {
  &.link-underline {
    a {
      text-decoration: underline;
      text-underline-offset: 8px;
      text-decoration-thickness: 2px;
      text-decoration-color: var(--cx-color-primary);
    }

    &.text-default a {
      text-decoration-color: var(--cx-color-text);
    }
  }
}

a {
  &.text-primary:hover {
    color: var(--cx-color-primary) !important;
  }
}


[data-tooltip] {
  cursor: pointer;

  &:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    /* customizable */
    transition: all 0.15s ease;
    padding: 0.5rem;
    border-radius: 1rem;
    border: 1px solid var(--cx-color-gray-light);
    background-color: var(--cx-color-inverse);
    box-shadow: 5px 5px 10px var(--cx-color-gray-light);
  }

  &:hover:before {
    /* needed - do not touch */
    opacity: 1;
    /* customizable */
    background: #fff;
    margin-top: 1rem;
    margin-left: 1rem;
  }
}

hr {
  &.hr {
    &-gray {
      border-top: 1px solid $ssab-gray-table;
    }

    &-blue {
      border-top: 1px solid $ssab-main-color;
    }
  }
}

.hidden {
  display: none;
}
