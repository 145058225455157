.text {
  &-primary {
    color: var(--cx-color-primary) !important;

    &-inverted {
      color: var(--cx-color-inverse);
    }

    &:hover{
      color: var(--cx-color-primary);
    }
  }

  &-secondary {
    color: var(--cx-color-secondary) !important;
  }

  &-preamble {
    color: var(--cx-color-accent-4);
    @include ssab-preamble();
    @include media-breakpoint-down(sm) {
      @include ssab-preamble-min();
    }
  }

  &-caption {
    @include ssab-caption();
  }

  &-bold {
    font-weight: bold !important;
  }

  &-gray {
    color: var(--cx-color-gray);
  }

  &-white {
    color: var(--cx-color-inverse);
  }

  &-lg {
    @include ssab-text-lg();
  }

  &-md {
    @include ssab-text-md();
    @include media-breakpoint-down(md) {
      @include ssab-text-sm();
    }
  }

  &-sm {
    @include ssab-text-sm();
  }

  &-paragraph-md {
    @include ssab-paragraph-md();
  }

  &-paragraph-lg{
    @include ssab-paragraph-lg();
  }

  &-opacity {
    opacity: 0.5;

    &-60{
      opacity: 0.6;
    }
  }

  &-default {
    color: var(--cx-color-text);
  }

  &-delayed {
    color: $ssab-delayed-color;
  }

  &-initial {
    text-transform: initial !important;
  }

  &-green {
    color: $ssab-success-green;
  }

  &-yellow {
    color: $ssab-yellow;
  }

  &-red {
    color: $ssab-status-blocked;
  }

  &-underline {
    text-decoration: underline;
    &-blue{
      text-decoration: underline;
      text-underline-offset: 8px;
      text-decoration-thickness: 2px;
      text-decoration-color: var(--cx-color-primary);
      &:hover{
        text-decoration-thickness: 2px;
        text-decoration-color: var(--cx-color-primary);
      }
    }
    &-black{
      text-decoration: underline;
      text-underline-offset: 8px;
      text-decoration-thickness: 2px;
      text-decoration-color: var(--cx-color-text);
      &:hover{
        text-decoration-thickness: 2px;
        text-decoration-color: var(--cx-color-text);
      }
    }
  }

  &-capitalize-first::first-letter{
      text-transform: uppercase;
  }

  &-space {
    &-normal {
      white-space: normal;
    }
    &-prewrap{
      white-space: pre-wrap;
    }
    &-nowrap{
      white-space: nowrap;
    }
  }

  &-error{
    color: $ssab-attention-red;
  }
}
