ssab-cx-product-facet-navigation {
  @extend %cx-product-facet-navigation;
  margin-bottom: 0;
  background-color: $ssab-white;

  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;
  }

  .not-shown {
    max-width: 0 !important;
    max-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .cx-page {
    background-color: $ssab-white;
    height: 100%;
    padding: 1rem;

    @include media-breakpoint-up(md) {
      padding: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 6rem 1rem 0 4rem;
    }
  }

  @include media-breakpoint-only(md) {
    ssab-cx-active-facets {
      .text-clear, hr.hr-gray {
        display: none;
      }
    }
  }
}

ssab-cx-facet-list {
  @extend %cx-facet-list;

  @include media-breakpoint-down(md) {
    ssab-cx-active-facets {
      .text-clear, hr.hr-gray {
        display: block;
      }
    }
    &.active.dialog {
      top: 5rem;

      .inner {
        background-color: $ssab-white;
        height: 100vh;
        width: 100vw;
        padding: 0 1rem 1rem 1rem;

        .list-facet {
          @include addScrollBar();
          height: 50vh;
        }

        h4 {
          padding-top: 1rem;
          padding-bottom: 2rem;
          background-color: $ssab-white;
        }
      }
    }
  }

  ssab-cx-show-inches-toggle {
    .cx-show-inches-plp {
      padding: 0.625rem 0.4rem;
      border-bottom: 1px solid var(--cx-color-light);
    }
  }
}

ssab-cx-active-facets {
  @extend %cx-active-facets;

  a {
    background-color: $ssab-blue-lightest;
    border-radius: 25px;
    padding: 0.5rem;
    width: inherit;
    position: relative;

    > span {
      white-space: normal;
      margin-right: 10px;
    }

    cx-icon {
      position: absolute;
      right: 0;
    }
  }

  .text-clear {
    display: inline;
  }

  @include media-breakpoint-down(md) {
    &[class*="col-"] {
      padding-left: 0;
      padding-right: 0;
    }
    a {
      width: fit-content;
      padding: 0 0.5rem;
      display: inline-block;

      cx-icon {
        position: relative;
      }
    }
  }
}

ssab-cx-facet {
  @extend %cx-facet;

  button.heading {
    text-align: left;
  }

  a, button {
    color: $ssab-main-color;
  }

  &.multi-select a.value {
    align-items: center;

    &::before {
      border: 2px solid var(--cx-color-primary);
      margin-inline-end: 1rem;
      content: '';
      width: 1.5rem;
      height: 1.3rem;
    }

    &.selected::before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='-1 -1 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.7102 1.2626C18.6172 1.16887 18.5066 1.09447 18.3848 1.0437C18.2629 0.992935 18.1322 0.966797 18.0002 0.966797C17.8682 0.966797 17.7375 0.992935 17.6156 1.0437C17.4937 1.09447 17.3831 1.16887 17.2902 1.2626L7.00019 11.5626L2.71019 7.2626C2.52188 7.07429 2.26649 6.9685 2.00019 6.9685C1.73388 6.9685 1.47849 7.07429 1.29019 7.2626C1.10188 7.4509 0.996094 7.70629 0.996094 7.9726C0.996094 8.2389 1.10188 8.49429 1.29019 8.6826L7.00019 14.3826L18.7102 2.6826C18.8039 2.58963 18.8783 2.47903 18.9291 2.35717C18.9798 2.23531 19.006 2.10461 19.006 1.9726C19.006 1.84058 18.9798 1.70988 18.9291 1.58802C18.8783 1.46616 18.8039 1.35556 18.7102 1.2626Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
    }
  }

  @include media-breakpoint-down(md) {
    &:nth-of-type(-n + #{$intialExpandedFacets}) {
      &.collapsed {
        .facet {
          &-range,
          &-dropdown,
          &-input-search {
            display: none;
          }
        }
      }
    }

    &:nth-of-type(n + #{$intialExpandedFacets + 1}) {
      &:not(.expanded) {
        .facet {
          &-range,
          &-dropdown,
          &-input-search {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    // first number of facets
    &:nth-of-type(-n + #{$intialExpandedFacetsLarge}) {
      &.collapsed {
        .facet {
          &-range,
          &-dropdown,
          &-input-search {
            display: none;
          }
        }
      }
    }

    // other facets lower in the list
    &:nth-of-type(n + #{$intialExpandedFacetsLarge + 1}) {
      &:not(.expanded) {
        .facet {
          &-range,
          &-dropdown,
          &-input-search {
            display: none;
          }
        }
      }
    }
  }
}
