cx-page-layout.ProductDetailsPageTemplate {
  padding: 2rem 0;
  filter: drop-shadow($ssab-template-drop-shadow);

  @include media-breakpoint-up(md) {
    padding-top: unset;
  }

  cx-page-slot.Summary {
    @include container-max-widths();

    display: flex;
    flex-direction: column;
    padding: unset;

    @include media-breakpoint-up(md) {
      margin-left: unset;
      margin-right: unset;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
      grid-column-gap: unset;
      margin-left: auto;
      margin-right: auto;
    }

    .product-detail-summary {
      display: grid;
      grid-template-columns: 1fr;

      @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr;
      }

      @include media-breakpoint-up(xxl) {
        grid-template-columns: 5fr 6fr;
      }

      .ssab-cx-product-intro {
        grid-row: 1 / 1;
      }

      cx-product-images,
      ssab-cx-product-images {
        grid-row: 1;
        position: relative;

        .ssab-icon{
          position: absolute;
          top: 2rem;
          right: 2rem;
        }

        @include media-breakpoint-down(md) {
          .ssab-icon{
            top: 1rem;
            right: 1rem;
          }
        }

        @include media-breakpoint-down(sm) {
          background-color: white;
          .ssab-icon{
            right: 2rem;
          }
        }

        @include media-breakpoint-up(md) {
          grid-row: 1 / 5;
          background-color: var(--cx-color-inverse);
        }
      }

      .summary {
        grid-row: 2;

        @include media-breakpoint-up(md) {
          grid-row: 1;
        }
      }

      ssab-cx-product-attributes {
        grid-row: 3;

        @include media-breakpoint-up(md) {
          grid-row: 2;
        }

        @include media-breakpoint-up(xxl) {
          padding: 1.5rem 6.25rem
        }
      }
    }
  }
}
