ssab-cx-banner-column {
  cx-generic-link,
  .disabled-carousel-item {
    position: relative;

    cx-media.has-icon {
      z-index: -10;
      flex: 1;
    }

    a,
    .carousel-item {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .carousel-icon {
      height: 100%;
      width: 100%;
      padding: 1rem;
      background-color: var(--cx-color-primary);
      position: absolute;
      top: 0;
      flex: 1;

      i {
        width: 100%;
        height: 100%;
      }
    }
  }

  cx-generic-link {
    .carousel-icon {
      &:hover {
        background-color: $ssab-banner-background-color;

        i {
          @include filter-primary-color();
        }
      }
    }
  }
}
