// Scanner common styles
scanner-home,
app-products,
app-product-card,
app-document-page,
app-email-dialog,
app-dropdown-menu,
app-order,
app-how-to-scan {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0.5rem;
    box-sizing: border-box;
    margin: 0 1rem;
  }

  .btn-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      margin: 0 0.5rem 0 0;
      width: 1rem;
      stroke: $ssab-white;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 1rem 0;

    .btn {
      width: 50%;
      line-height: 16px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    background-color: $ssab-white;
    justify-content: center;
    padding: 0 1rem;
    width: 100%;

    .btn-block {
      width: 100%;
    }
  }
}
