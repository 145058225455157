ssab-cx-searchbox{
  @extend %ssab-cx-searchbox;
  @include media-breakpoint-between(xs, sm) {
    position: relative;
    z-index: 1;
    label{
      max-width: 40px;
      &:not(.dirty){
        button{
          &.reset{
            display: block;
          }
        }
      }
      &.dirty{
        #dirty-toggle{
          display: block;
        }
      }
      input{
        margin-left: unset;
        background-color: $ssab-white;
        top: 0px;
        height: 52px;
        padding-left: 3rem;
      }
      button{
        &.reset{
          right: 90px;
          top:-46px;
          left: unset;
          position: absolute;
          &:not(.icon-inside){
            display: none;
          }
        }
      }
      cx-icon{
        &.search{
          margin: unset;
          right: 0px;
          position: absolute;
        }
      }
    }
    .results{
      top:52px;
      .products{
        display: block;
      }
    }
  }

  .icon-navigation-close{
    top: 2px;
    right: 0;
  }
}

%ssab-cx-searchbox{
  @extend %cx-searchbox;

  > * {
    @include media-breakpoint-up(md) {
      background-color: var(--cx-color-inverse);
    }
  }

  label {
    width: auto;
    max-width: none;
    min-width: unset;
    input::placeholder{
      color: var(--cx-color-gray-light);
    }
  }

  .results{
    .products{
      a{
        &.has-media{
          grid-template-columns: 60px 1fr;
          align-items: center;
        }
        cx-media{
          min-height: 60px;
          grid-row: 1 / 2;
        }
        h4.name{
          display: flex;
          align-items: center;
          height: 100%;
          white-space: pre-wrap;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    border: 1px solid var(--cx-color-primary);
  }
}

body{
  @extend %cx-searchbox__body;
  &.searchbox-is-active {
    &.has-searchbox-results {
      ssab-cx-searchbox {
        .results {
          display: block;
        }
      }
    }
    @include media-breakpoint-between(xs, sm) {
      ssab-cx-searchbox{
        position: initial;
        .search{
          left: 1rem;
          top: 15px;
          position: absolute;
          z-index: 20;
        }
      }
    }
  }

  &:not(.searchbox-is-active) {
    @include media-breakpoint-down(sm) {
      // hide the input on mobile when there's no interaction with searchbox
      ssab-cx-searchbox {
        input {
          // we cannot use display:none, visible:hidden or opacity: 0
          // as this will no longer emit a focus event to the controller logic
          width: 0;
          padding: 0;
        }
        button{
          &.reset {
            display: none;
          }
        }
      }
    }
  }
}
