cx-page-layout {
  display: block;
  width: 100%;

  > header {
    background-color: var(--cx-color-background);
    text-align: center;
    padding-bottom: 25px;
    padding-top: 25px;
  }

  cx-page-slot {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1 1 var(--cx-flex-basis, 100%);

    > * {
      flex: 1 1 var(--cx-flex-basis, 100%);
    }
  }

  &.footer {
    background-color: var(--cx-color-dark);
    @include forFeature('a11yImproveContrast') {
      background-color: var(--cx-color-secondary);
    }

    color: var(--cx-color, var(--cx-color-inverse));
  }
}

cx-page-slot {
  // smartedit requires every slot to be rendered in block mode
  // most likely it cannot make an overlay without some minimal sizingn
  display: block;
}

cx-paragraph {
  display: block;
}

cx-category-navigation {
  display: block;
}

cx-page-slot.cx-bottom-header-slot {
  @include forFeature('cmsBottomHeaderSlotUsingFlexStyles') {
    display: flex;

    > * {
      flex: 1 1 var(--cx-flex-basis, 100%);
    }
  }
}
