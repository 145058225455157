//Filter mixins for changing icon colors

@mixin filter-gray(){ //#707070
  filter: invert(46%) sepia(10%) saturate(10%) hue-rotate(2deg) brightness(92%) contrast(89%);
}

@mixin  filter-gray-light(){ //#C8C8C8
  filter: invert(77%) sepia(87%) saturate(1%) hue-rotate(55deg) brightness(89%) contrast(89%);
}

@mixin filter-red(){ //FF0000
  filter: invert(18%) sepia(85%) saturate(7253%) hue-rotate(359deg) brightness(114%) contrast(120%);;
}

@mixin  filter-white(){
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(170deg) brightness(103%) contrast(102%);
}

@mixin  filter-royal-blue(){ //#3E66FB
  filter: invert(31%) sepia(96%) saturate(2121%) hue-rotate(220deg) brightness(100%) contrast(106%);
}

@mixin  filter-green(){ //#3CC13B
  filter: invert(49%) sepia(97%) saturate(396%) hue-rotate(71deg) brightness(106%) contrast(86%);
}

@mixin  filter-default-blue(){ //#3E66FB
  filter: invert(11%) sepia(30%) saturate(7266%) hue-rotate(210deg) brightness(89%) contrast(103%);
}

@mixin  filter-yellow(){ //#FFA700
  filter: invert(57%) sepia(72%) saturate(691%) hue-rotate(0deg) brightness(104%) contrast(105%);
}

@mixin filter-primary-color() { //#002664
  filter: brightness(0) saturate(100%) invert(13%) sepia(33%) saturate(4552%) hue-rotate(204deg) brightness(97%) contrast(108%);
}

@mixin filter-secondary-color() { //#72B5CC
  filter: brightness(0) saturate(100%) invert(74%) sepia(14%) saturate(1012%) hue-rotate(149deg) brightness(89%) contrast(90%);;
}

@mixin filter-tertiary-color() { //#0C367A
  filter: brightness(0) saturate(100%) invert(9%) sepia(92%) saturate(3572%) hue-rotate(214deg) brightness(93%) contrast(91%);
}

@mixin filter-accent4-color() { // #001333
  filter: brightness(0) saturate(100%) invert(6%) sepia(54%) saturate(3146%) hue-rotate(204deg) brightness(96%) contrast(103%);
}
