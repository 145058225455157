ssab-cx-claim-request-form {
  .cx-claim-request-form {
    .cx-page-header-title {
      @include media-breakpoint-up(lg) {
        padding-bottom: 8rem;
      }
    }

    ul {
      padding: 0;
      margin: 0 0 0 1rem;
    }

    .reason-text-area textarea{
      height: 120px;
    }

    .no-material {
      color: $ssab-attention-red;
    }

    &-sections {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 1.5rem;
      margin-top: $ssab-padding-margin-default * 2;
      margin-bottom: $ssab-padding-margin-default * 2;

      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, minmax(400px, 1fr));
        grid-column-gap: 1.5rem;
      }

      .cx-claim-request-form-section {
        padding: 1rem;
        background-color: $ssab-white;

        @include media-breakpoint-up(lg) {
          padding: 2rem 4rem;
        }

        &.cx-claim-request-form-section-gray {
          background-color: $ssab-gray-lightest;
        }

        &.order-details {
          grid-column: 1;
        }

        &.contact-details {
          @include media-breakpoint-up(lg) {
            grid-column: 1;
            grid-row: 2;
          }
        }

        &.claim-details {
          @include media-breakpoint-up(lg) {
            grid-column: 2;
            grid-row: 1 / span 2;
          }
        }


        &.claim-notification {
          @include ssab-paragraph-md();

          @include media-breakpoint-up(lg) {
            grid-column: 1/span 2;
            grid-row: 3;
          }
        }

        .field {
          &-header {
            @include ssab-H3-24();

            margin-bottom: 2rem;

            label {
              @extend .text-opacity;

              min-height: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: 0;
            }
          }

          &-content {
            margin: 0 0 1.5rem 0;

            label {
              @extend .text-opacity;

              &.required {
                &::after {
                  content: ' *';
                  display: inline;
                }
              }
            }

            .selected-items-container {
              margin-top: 0.5rem;

              .selected-item {
                display: inline-block;
                margin: 0 0.5rem 0.5rem 0;
                padding-right: 0.5rem;
                line-height: 34px;
                height: 34px;
                border-radius: 2px;
                background-color: $ssab-gray-lightest;
                border: 1px solid $ssab-gray-table;
              }

              .material-quantity-input {
                display: inline-block;
                width: 6rem;
              }

              .material-unit-select {
                width: 6rem;

                .ng-select-container {
                  border: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
