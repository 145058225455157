cx-paragraph{
  h5.claims-title{
    padding-top: 3.5rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  .claims{
    &-paragraph, &-title, &-heading{
      padding: 0 3.5rem;
    }
    &-introduction{
      padding: 3.5rem 3.5rem;
    }
    &-heading{
      padding-top: 6rem;
    }
  }
  @include media-breakpoint-down(md){
    .claims{
      &-paragraph, &-title, &-heading{
        padding: 0 1.5rem;
      }
      &-introduction{
        padding: 2rem 1.5rem;
      }
      &-heading{
        padding-top: 4.5rem;
      }
    }
  }

  @include media-breakpoint-between(xs, sm){
    h5.claims{
      padding-top: 2.5rem;
    }
    .claims{
      &-paragraph, &-title, &-heading{
        padding: 0 1rem;
      }
      &-heading{
        padding-top: 3rem;
      }
      &-introduction{
        padding: 1.5rem 1rem;
      }
    }
  }
}
