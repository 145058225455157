ssab-cx-claims-search {
  .cx-claims {
    @include searchFormAndTable();

    &-form {
      @include media-breakpoint-down(md) {
        @include datePickerTabletAndMobile();
      }
    }

    &-tab {
      padding-bottom: 2rem;
      color: $ssab-white;

      @include media-breakpoint-down(md) {
        @include tabTabletAndMobile();
        white-space: normal;
      }

      .ssab-icon {
        @include media-breakpoint-down(md) {
          filter: unset;
        }
      }
    }

    &-search {
      .table-responsive {
        @include addScrollBar();
        overflow-y: hidden;

        ssab-cx-claim-list-item {
          display: contents;

          .count {
            align-self: center;
            border: 0;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            line-height: 22px;
            z-index: 3;
            background: $ssab-attention-red;
            color: $ssab-white;
            font-size: 11px;
            text-align: center;
            font-weight: normal;
          }

          .btn-icon {
            position: relative;

            .count {
              position: absolute;
              top: -8px;
              right: -8px;
              width: 20px;
              height: 20px;
              line-height: 20px;
            }
          }
        }
      }
    }

    &-details {
      @include media-breakpoint-up(lg) {
        padding: 2.5rem 4rem;
      }

      &-tab {
        h4 {
          font-weight: initial;
          cursor: pointer;
          position: relative;

          + h4 {
            margin-left: 4rem;
          }

          &.selected {
            text-decoration: underline;
            text-underline-offset: 8px;
            text-decoration-thickness: 2px;
            font-weight: bold;
          }

          .count {
            display: inline-block;
            margin-left: 0.5rem;
          }
        }
      }

      ssab-cx-claim-request-form {
        padding: 0;
        filter: drop-shadow($ssab-template-drop-shadow);
      }

      .cx-claims-table {
        margin-top: 1.5rem;

        th {
          padding: 0.5rem 1rem;
          background-color: $ssab-gray-table;
          color: var(--cx-color-text);
          font-size: 12px;
          white-space: nowrap;
          font-weight: 500;
        }
      }

      .cx-claims-messages {
        margin-top: 1.5rem;
        width: calc(100vw - 2rem);

        @include media-breakpoint-up(lg) {
          width: 36rem;
        }

        .cx-claims-messages-wrapper {
          margin-bottom: 1.5rem;
          max-height: 25rem;
          display: block;
          @include addScrollBar();

          .cx-claims-messages-item {
            padding: 1rem;
            margin-bottom: 1rem;
            border-radius: 5px;
            background: $ssab-product-background;

            &.own {
              background: $ssab-gray-table;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .cx-claims-messages-form {

        }
      }
    }
  }

  .cx-claims-list-external {
    background: $ssab-white;
    padding: 3rem;
  }
}

ssab-cx-change-assignee-dialog,
ssab-cx-change-status-dialog {
  &.modal.show {
    .modal-dialog {
      .modal-content {
        padding: 2.5rem;

        .modal-header {
          padding: 0;
          margin-bottom: 0.5rem;
          border-bottom: 0;
        }

        .modal-body {
          padding: 0;
        }
      }
    }
  }

  .field {
    &-content {
      margin: 0 0 1.5rem 0;

      label {
        @extend .text-opacity;

        &.required {
          &::after {
            content: ' *';
            display: inline;
          }
        }
      }

      ssab-datepicker-input {
        .input-group {
          border-bottom: 0;
        }
      }
    }
  }

  .ng-select {
    .ng-dropdown {
      &-header {
        input {
          border: unset;
          display: inline;
          width: 90%;
        }
      }

      &-footer {
        padding: 8px 10px;
      }
    }
  }

  .form-check-statuses {
    padding-inline-start: 1.75rem;

    input[type=radio] {
      margin-left: -1.75rem;
    }
  }

  .evaluated-document {
    background-color: $ssab-gray-lightest;
    border-radius: 4px;

    h4 {
      color: $ssab-main-color;
    }
  }
}

.claims-status {
  display: inline-block;
  max-width: 6.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;

  &.status {
    &-submitted {
      color: $ssab-status-open;
    }

    &-draft {
      color: $ssab-status-draft;
    }

    &-evaluated,
    &-inProcess {
      color: $ssab-info-blue;
    }

    &-completed {
      color: $ssab-status-delivered;
    }

    &-rejected {
      color: $ssab-status-closed;
    }
  }
}
