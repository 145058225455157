footer {
  > cx-page-layout {
    &.footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: var(--cx-color-primary);

      > cx-page-slot {
        ssab-cx-footer-container {
          .footer {
            @include container-max-widths();
            margin: auto;
            padding: 3rem 1.5rem;
            font-size: 15px;

            .footer-container {
              .footer-vision-box-wrapper {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 10px;
                display: grid;

                a {
                  color: white !important;
                  text-transform: uppercase;
                  text-decoration: none;
                  letter-spacing: 2px;
                  padding-bottom: 2px;
                  border-bottom: solid 2px;
                }

                .footer {
                  &-icons a {
                    @include filter-white();

                    border-bottom: unset;
                    margin-right: 0.5rem;
                  }
                }

                #ot-sdk-btn {
                  border-width: 0 0 2px 0;
                  border-color: white;
                  color: white;
                  background-color: unset;
                  text-transform: uppercase;
                  text-decoration: none;
                  letter-spacing: 2px;
                  text-align: left;
                  padding: 1rem 0 2px 0;
                  font-size: 15px;
                }
              }
            }

            @include media-breakpoint-up(xxl) {
              .footer-container {
                .footer-vision-box-wrapper {
                  .footer-icons a {
                    margin-right: 1rem;
                  }
                }
              }
            }

            @include media-breakpoint-only(md) {
              .footer-container {
                .footer-vision-box-wrapper {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  grid-gap: 2rem;

                  ssab-cx-footer-column {
                    &:first-child {
                      grid-column: 1;
                      grid-row: 1/3;
                    }

                    &:nth-child(2) {
                      grid-column: 2;
                    }

                    &:nth-child(3) {
                      grid-column: 2;
                      grid-row: 2;
                    }

                    &:nth-child(4) {
                      grid-column: 3;
                    }
                  }
                }
              }
            }

            @include media-breakpoint-between(xs, sm) {
              .footer-container {
                padding: 1rem;
                grid-gap: 1rem;

                .footer-vision-box-wrapper {
                  grid-template-columns: 1fr;

                  ssab-cx-footer-column {
                    grid-column: auto;
                    grid-row: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
