main {
  ssab-cx-navigation-ui {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
}

ssab-cx-navigation-ui {
  background-color: $ssab-white;
  @extend %cx-navigation-ui;
  flex-direction: column;

  cx-icon {
    padding-inline-start: unset;
  }

  .heading-wrapper {
    height: 100%;
    min-width: 275px;
    width: 100%;
    border: 0;
    text-transform: inherit;
    letter-spacing: 1px;
    display: block;
    line-height: normal;
    color: var(--cx-color-primary);
    @include ssab-text-lg();

    h4, a {
      padding: 1rem 2rem;
      cursor: pointer;
    }

    a {
      color: var(--cx-color-primary);
    }
  }

  @include media-breakpoint-down(md) {
    &.flyout {
      h5 {
        border-bottom: unset;
      }

      .heading-wrapper {
        border: 0;
      }
    }
  }

  .cx-no-link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: currentColor;
    opacity: 0.5;

    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid var(--cx-color-medium);
      padding: 0.5rem 0;
    }

    div {
      display: block;
      width: 100%;
      padding: 0.5rem 2rem;
    }
  }
}

ssab-cx-category-navigation {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $ssab-white;

  .category-navigation-scroll-wrapper {
    @include media-breakpoint-up(lg) {
      @include addScrollBar();
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &:first-of-type > ssab-cx-navigation-ui {
      margin-top: 4rem;
    }
  }

  .icon-navigation-close {
    position: absolute;
    left: 230px;
    margin-top: 1rem;
  }

  ssab-cx-navigation-ui {
    width: 280px;

    &.flyout {
      a {
        white-space: normal;
        word-break: break-word;
      }
    }

    .wrapper {
      top: 0;
      position: absolute;
      left: 280px;
      height: 100vh;
      display: none;
      background-color: $ssab-white;
    }

    nav.is-open .wrapper {
      display: block;
    }

    .childs {
      margin-top: 7rem;

      h5.childs-title {
        @extend .text-gray;
        letter-spacing: 2px;
        padding: 1rem 2rem;
        font-weight: bold;

        &:hover {
          @extend .text-gray;
        }
      }

      nav cx-generic-link a {
        padding: 0.5rem 2rem;
        color: var(--cx-color-primary);

        &:hover {
          text-decoration-line: underline;
          text-decoration-color: var(--cx-color-primary);
        }
      }
    }
  }

  .logo {
    max-height: 3rem;
    padding: 0 2rem;
    margin-top: 4rem;
  }

  .navigation-user-dropdown {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid var(--cx-color-gray-light);
  }

  @include media-breakpoint-down(md) {
    @include addScrollBar();
    position: inherit;
    width: 60vw;
    .ssab-icon {
      left: unset;
      right: 1rem;
    }
    ssab-cx-navigation-ui {
      height: auto;
      width: 100%;

      .heading-wrapper {
        height: auto;
        min-width: 100%;

        .cx-icon {
          transform: rotate(90deg);
        }
      }

      .childs {
        margin-top: unset;

        nav {
          cx-generic-link {
            width: 100%;

            a {
              padding: 1rem 2rem;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    width: 100vw;
  }
}
