ssab-cx-carousel {
  @extend %cx-carousel;

  position: relative;
  margin: 0 2rem;

  h3 {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .previous, .next {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    color: var(--cx-color-primary);
    padding: 0.3rem 0.8rem;
  }

  .previous {
    left: -30px;
  }

  .next {
    right: -30px;
  }

  .item {
    padding: 0 1.5rem;

    ssab-cx-banner-column {
      cx-generic-link,
      .disabled-carousel-item {
        a,
        .carousel-item {
          min-height: 164px;
          width: 100%;
          display: block;

          @include media-breakpoint-down(md) {
            min-height: 144px;
          }
        }
      }
    }
  }

  .carousel-panel {
    .slide {
      &.active {
        display: flex;
        justify-content: center;

        .item {
          align-self: end;
        }

        .lastSlide {
          order: 9999
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .previous {
      left: 0px;
    }
    .next {
      right: 0px;
    }
    .item {
      padding: 0 0.5rem;
    }
    margin: unset;
    padding: 0 2rem;
  }
  @include media-breakpoint-down(sm) {
    .item {
      padding: 0 0.75rem;
    }
  }
}
