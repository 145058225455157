ssab-cx-product-summary {
  ssab-cx-product-intro {
    @extend %cx-product-intro;
    padding-inline-end: unset;
    padding-inline-start: unset;
  }

  .summary {
    @extend %cx-product-summary;
    background-color: var(--cx-color-inverse);
    padding: 2rem 2.5rem 0 2.5rem;
    display: table;

    .cx-price-pdp {
      padding-bottom: 2.5rem;
      border-bottom: 1px solid var(--cx-color-gray-light);
    }

    .cx-description {
      padding-top: 2.5rem;

      @include media-breakpoint-up(xxl) {
        padding: 2.5rem 3.75rem 0;
      }
    }

    .product-data-sheet-link {
      display: block;
      padding: 1rem 0;

      @include media-breakpoint-up(xxl) {
        padding-left: 3.75rem;
      }
    }

    ssab-cx-add-to-cart {
      .stepper-price{
        .icon-navigation-minus-small{
          margin-right: 1rem;
        }
        .icon-navigation-plus-small{
          margin-left: 1rem;
        }
      }
    }
  }

  ssab-cx-product-attributes {
    @extend %cx-product-attributes;
    padding: 1.5rem 2.5rem 2.5rem 2.5rem;
    background-color: var(--cx-color-inverse);

    table {
      margin-bottom: unset;

      &.table-borderless {
        tr {
          border-style: none;
        }

        td {
          padding: 6px 0 6px 0;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .summary{
      .cx-price-pdp {
        text-align: right;
        .units{
          padding-right: unset;
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    .summary {
      padding: 2rem 2.5rem 0 2.5rem;
    }
    ssab-cx-product-attributes{
      padding: 2rem 2.5rem 2rem 2.5rem;
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .summary{
      padding: 2rem 1rem 0 1rem;
    }
    ssab-cx-product-attributes{
      padding: 2rem 1rem 1rem 1rem;
      table.table-borderless{
        th{
          padding-right: 0.5rem;
        }
        td{
          padding-left: 1rem;
        }
      }
    }
  }
}
