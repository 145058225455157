ssab-cx-input-range{
  background-color: white;
  min-height: $ssab-input-height-default;
  .range{
    &-control{
      padding: 0.6875rem 0.75rem;
      margin: unset;
    }
    &-content{
      padding: 1rem;
    }
  }
}

ssab-cx-input{
  &.w-100{
    input.ssab-input{
      width: 100%;
    }
  }
}
