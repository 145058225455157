ssab-cx-banner-grid-tile {
  cx-media img {
      width: 100%;
  }

  .tile {
    background-color: var(--cx-color-primary);
    height: 100%;
    position: relative;

    &-box {
      padding: 3rem;
      position: absolute;
      bottom: 0;

      .tile-icon{
        width: 60px;
        height: 60px;
      }
    }

    &-image {
      background-color: $ssab-banner-gray-background;
      height: 100%;

      &-box {
        padding: 2rem 1rem;

        .tile-icon {
          background-color: var(--cx-color-primary);
          padding: 0.75rem 0.5rem;
          margin-right: 1rem;
        }
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .tile-box {
      position: relative;
    }
  }
}
