.cart-header {
  margin-top: $ssab-padding-margin-default * 2;
  margin-bottom: $ssab-padding-margin-default * 2;

  @include media-breakpoint-between(xs, sm) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.cart-header,
.new-address,
.order-totals,
.order-summary {
  .header-fields {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
    margin-top: $ssab-padding-margin-default * 2;
    margin-bottom: $ssab-padding-margin-default * 2;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, minmax(400px, 1fr));
      grid-column-gap: 1.5rem;
    }

    .contact-details {
      @include media-breakpoint-up(lg) {
        grid-column: 1;
        grid-row: 2;
      }

      .email-list {
        list-style: none;
        padding: 0;
        margin-bottom: 0.5rem;
      }

      ssab-cx-input {
        width: calc(100% - 2.5rem);

       .form-control {
          width: 100%;
        }

        + .ssab-icon {
          margin-top: 0.625rem;
        }
      }
    }

    .payment-details{
      grid-column: 1;
    }

    .delivery-details{
      @include media-breakpoint-up(lg) {
        grid-column: 2;
        grid-row: 1 / span 2;
      }
      .add-new-address a {
        color: var(--cx-color-primary);
        cursor: pointer;
        text-decoration: underline;
        @include ssab-label-md();
      }

      .field-content .warehouseAddress {
        margin-right: 0;
        margin-bottom: 0;
        padding-bottom: 20px;

        &:not(div:first-of-type) {
          border-top: 1px solid $ssab-gray-light;
          margin-top: 0;
          padding-top: 20px;
        }
      }
    }

    &.stretched-contact-details {
      .contact-details {
        @include media-breakpoint-up(lg) {
          grid-row: 1/span 2;
        }
      }

      .payment-details{
        @include media-breakpoint-up(lg) {
          grid-column: 2;
        }
      }

      .delivery-details {
        @include media-breakpoint-up(lg) {
          grid-row: 2/ span 1;
        }
      }
    }
  }

  .summary {
    .separator {
      border-top: 1px solid $ssab-gray-light;
      margin-top: 10px;
      padding-top: 10px;
    }
  }

  .place-order {
    padding: 1rem;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      width: 100%;
      padding: 0 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    .form-check {
      padding: 0 0 0 1.3rem;

      @include media-breakpoint-up(md) {
        padding: 1.5rem 0 0 2.25rem;
      }
    }

    .place-order-button {
      width: 100%;
      margin: 0;

      @include media-breakpoint-up(md) {
        float: right;
        margin: 0.5rem 0 0;
        width: auto;
      }
    }

    .remove-cart-button {
      width: 100%;
      margin: 0;

      @include media-breakpoint-up(md) {
        float: left;
        margin: 0.5rem 0 0;
        width: auto;
      }
    }
  }

  .field {
    &-header {
      @include ssab-H3-24();

      padding-bottom: 3rem;
    }

    &-content{
      margin: 0 3rem 1.5rem 0;

      label {
        @extend .text-opacity;
      }
    }
  }


  @include media-breakpoint-only(md) {
    .contact-details {
      margin: 1.5rem 0;
    }

    .delivery-details {
      margin-bottom: 1.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    .contact-details,
    .payment-details,
    .delivery-details {
      height: auto;
    }

    .field {
      &-content {
        margin: 0px 0 1.5rem 0px;
        position: relative;
      }

      &-header {
        padding-bottom: 2rem;
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    .contact-details {
      margin: 1rem 0;
    }
    .delivery-details {
      margin-bottom: 1rem;
    }
  }
}

.order-totals {
  label {
    width: 100%;
    font-weight: bold;
  }

  .total-sum {
    border-top: 2px solid var(--cx-color-inverse);
    margin-top: 3px;
    padding-top: 1rem;
    margin-right: 0;
  }
}

.payment-details{
  grid-column: 1;

  &.stretched-contact-details {
    grid-column: 2;

    @include media-breakpoint-up(md) {
      grid-column: 2;
    }
  }
}

