.input-group-icon {
  span {
    position: absolute;
    margin-left: 10px;
    height: 48px;
    display: flex;
    align-items: center;
  }

  input {
    padding-left: 35px;
  }
}

input.form-control,
.ng-select .ng-select-container,
textarea.form-control {
  border-radius: unset;
  @include ssab-paragraph-lg();

  .ng-placeholder,
  &::placeholder {
    color: var(--cx-color-gray-light) !important;
  }
}

input[type=radio] {
  &:checked {
    border-color: var(--cx-color-primary) !important;

    &::after {
      background-color: var(--cx-color-primary);
    }
  }
}

ng-select,
select {
  &.is-invalid {
    .ng-select-container {
      border-color: var(--cx-color-danger);
    }
  }

  &.ssab-select {
    .ng-select-container {
      border: unset;
      background-color: transparent;
    }

    &-footer {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            color: $ssab-text-color;
          }
        }

        .ng-clear-wrapper {
          display: none;
        }

        .ng-arrow-wrapper {
          @include media-breakpoint-up(lg) {
            width: 35px;
            padding-right: 10px;
          }
        }
      }

      .ng-dropdown-footer {
        border-top: unset !important;
        padding: 8px 10px !important;
        cursor: pointer;

        &:hover {
          background-color: var(--cx-color-light);
        }
      }
    }
  }

  &.ng-select {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: $ssab-input-dropdown-height;
    }

    .ng-dropdown-panel .scroll-host {
      @include addScrollBar();
    }

    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          padding-left: 12px;

          .ng-placeholder {
            position: inherit;
          }

          .ng-value {
            background-color: $ssab-white;

            &:after {
              content: ", ";
            }

            &:nth-last-child(2) {
              &:after {
                content: "";
              }
            }
          }
        }
      }

      .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          .ng-option {
            &.ng-option-selected {
              .form-check {
                input[type="checkbox"] {
                  background-color: var(--cx-color-primary);

                  &::after {
                    height: 7px;
                    width: 14px;
                    top: 3px;
                    left: 2px;
                    position: absolute;
                    content: '';
                    border-width: 0 0 2px 2px;
                    border-style: solid;
                    border-color: var(--cx-color-inverse);
                    transform: rotate(-45deg);
                  }
                }
              }
            }

            .form-check {
              padding-inline-start: 0;
              margin-right: 0.5rem;

              input[type="checkbox"] {
                pointer-events: none;
                margin-inline-start: 0;
                margin-top: -0.25rem;
              }
            }
          }
        }
      }
    }
  }

  &.ng-select,
  &.ng-select.ng-select-single {
    &.ng-select-focused {
      &:not(.ng-select-opened) {
        .ng-select-container {
          box-shadow: unset;
        }
      }
    }

    &.ng-select-opened {
      .ng-arrow-wrapper .ng-arrow {
        transform: rotate(180deg);
      }
    }

    .ng-select-container {
      height: $ssab-input-height-default;
      border-width: 1px;
      border-radius: unset;

      &:hover {
        box-shadow: unset;
      }

      .ng-value-container {
        .ng-input {
          top: 12px;

          input {
            @include ssab-paragraph-lg();
          }
        }
      }
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        @extend .ssab-icon;
        @extend .icon-chevron-down;
        background-size: unset;
        border-color: unset;
        border-style: unset;
      }
    }
  }

  @include media-breakpoint-between(xs, sm) {
    &.w-80vw {
      &.ng-select.ng-select-single .ng-select-container {
        .ng-value-container,
        .ng-value-container .ng-value {
          max-width: 80vw;
        }
      }
    }
  }
}

:focus,
.form-control:focus,
input:focus,
.ng-select-focused {
  outline-style: none;
}

.form-check {
  input[type=checkbox] {
    border-color: var(--cx-color-primary);

    &.check-color-inverse {
      border-color: $ssab-white;
    }

    &:checked::after {
      height: 7px;
      width: 14px;
      top: 3px;
      left: 2px;
    }
  }
}

.ssab-slider {
  &.ngx-slider {
    .ngx-slider-pointer {
      background-color: $ssab-white;
      border: 0.661351px solid rgba(0, 0, 0, 0.2);

      &.ngx-slider-active::after {
        background-color: $ssab-white;
      }
    }

    .ngx-slider-bar.ngx-slider-selection {
      background: $ssab-info-blue;
    }

    .ngx-slider-bar {
      background: $ssab-gray-light;
    }
  }
}
