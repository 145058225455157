ssab-cx-hero-banner {
  background-color: $ssab-main-color-darker;

  @include media-breakpoint-up(lg) {
    max-height: 660px;
  }

  @include media-breakpoint-up(xxl) {
    max-height: 330px;
  }

  cx-media img {
    width: 100%;
    height: 100%;
  }

  article{
    background: none;
    color: $ssab-white;
    align-items: stretch;

    .banner-content {
      background: linear-gradient(214.19deg, rgba(3, 40, 99, 0.88) -11.28%, rgba(0, 38, 100, 0.12) 144.45%);
      height: 100%;
      position: relative;

      section {
        padding: 1.5rem;
        position: relative;

        @include media-breakpoint-up(lg) {
          position: absolute;
          padding: 0 4rem;
          top: 60%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &.image-cover {
    article {
      @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
        max-height: 100%;
      }
    }

    cx-media img{
      object-fit: cover;
    }

    &.image-position0 cx-media img {
      object-position: 0 0;
    }

    &.image-position25 cx-media img {
      object-position: 0 25%;
    }

    &.image-position50 cx-media img {
      object-position: 0 50%;
    }

    &.image-position75 cx-media img {
      object-position: 0 75%;
    }

    &.image-position100 cx-media img {
      object-position: 0 100%;
    }
  }
}

