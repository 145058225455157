ssab-cx-not-found{
  color: var(--cx-color-primary);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  > section.vertical-align-item{
    height: 60vh;
  }

  .icon-warning{
    margin-bottom: 5rem;
    height: 160px;
    width: 180px;
  }
}
