ssab-cx-order-history {
  @extend %cx-order-history !optional;

  .cx-order-history-search-option {

    .ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          display: flex;
          overflow: hidden;
          max-width: calc(100% - 24px); /* Adjust width as necessary if problems */
        }
      }
    }
  }

  .cx-order {
    &-history {

      &-tab {
        padding-bottom: 2rem;
        color: $ssab-white;

        h3 {
          font-weight: normal;
          cursor: pointer;

          &.active {
            font-weight: bold;
          }
        }
      }

      &-order-item.cx-order-history-table tr th:first-child {
        padding-left: 1rem;
      }

      &-table {
        thead {
          tr th {
            padding: 0.75rem;

            &:first-child {
              padding-left: 4rem;
            }
          }
        }

        tbody {
          tr td {
            padding: 0.75rem;
          }
        }
      }

      &-download {
        border-top: 1px solid $ssab-border-color-default;
        width: 100%;
        padding: 1.5rem 0.5rem;
        background-color: $ssab-white;

        &.is-sticky {
          position: sticky;
          bottom: 0;
        }
      }

      &-body {
        background-color: $ssab-white;

        .table-responsive {
          overflow-x: auto;
        }
      }

      &-search {
        position: relative;

        .cx-page-header-title {
          padding-bottom: 2rem;
        }
      }

      @include searchFormAndTable();
    }
  }

  @include media-breakpoint-down(md) {
    .cx-order {
      &-dates {
        margin: unset;
        padding: unset;
      }

      &-history {

        &-tab {
          @include tabTabletAndMobile();
          white-space: normal;

          .ssab-icon {
            filter: unset;
          }
        }

        &-table {
          tr {
            width: $ssab-table-width;

            &:first-child {
              padding: unset;
            }

            th {
              padding: 1rem;
            }
          }

          td:first-child {
            padding: 1rem;
          }
        }

        &-body {
          background-color: $ssab-white;

          > div {
            padding: 1rem;
          }
        }
      }
    }
    @include datePickerTabletAndMobile();
  }

  ssab-cx-documents-result .entries-data {
    width: auto;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  .cx-documents-download {
    @include media-breakpoint-down(md) {
      width: calc(100vw - 40px);
    }
  }

  .multi-checkbox-all {
    margin-left: 3px;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    cursor: pointer;

    p {
      margin-left: -5px !important;
    }
  }
}

ssab-cx-order-history-tables {
  .table {
    tr.active {
      background-color: var(--cx-color-light);
    }

    .status {
      &-received {
        color: $ssab-status-received;
      }

      &-open {
        color: $ssab-status-open;
      }

      &-indelivery,
      &-delivered,
      &-partiallydelivered {
        color: $ssab-status-delivered;
      }

      &-invoiced,
      &-partiallyinvoiced {
        color: $ssab-status-invoiced;
      }

      &-closed {
        color: $ssab-status-closed;
      }

      &-blocked {
        color: $ssab-status-blocked;
      }

      &-cancelled,
      &-rejected {
        color: $ssab-status-cancelled;
      }
    }
  }

  .feedback {
    border: 1px solid $ssab-border-color-default;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;

    &-container {
      width: 70%;

      .rate-us {
        height: 160px;
      }

      textarea {
        height: 120px;
      }
    }
  }
}

ssab-cx-order-data-tables {
  display: block;
  padding: 2.5rem 1.25rem; //40px 20px
  width: 100%;

  @include media-breakpoint-up(lg) {
    padding: 3.125rem 2rem; //50px 32px
  }

  .empty-data-div {
    min-height: 150px;
  }

  .wrapper-entries-table {
    width: 100%;

    &.table-responsive {
      overflow-y: auto;
    }

    @include media-breakpoint-down(md) {
      .empty-data-div {
        width: 90vw;
        position: sticky;
        top: 0;
        left: 1rem;
      }
    }
  }

  .entries-data {
    tr {
      width: max-content;
    }

    th {
      padding: 0.5rem 1rem;
      background-color: $ssab-gray-table;
      white-space: nowrap;
      font-weight: 500;

      .ssab-icon.icon-system-info {
        position: relative;
        top: -2px;
      }
    }

    td {
      padding: 0.75rem 0.5rem;
      white-space: nowrap;
    }

    .w-150 {
      width: 150px;
    }

    &-tab {
      font-weight: unset;
      color: var(--cx-color-gray);
      padding: 0;
      padding-right: 3rem;

      &:hover, &.active {
        color: var(--cx-color-text);
        font-weight: bold;
      }
    }
  }


  @include media-breakpoint-down(md) {
    ssab-cx-documents-result {
      .empty-data-div {
        width: 90vw;
        position: sticky;
        top: 0;
        left: 1rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    ssab-cx-documents-result .cx-documents-download {
      position: sticky;
      top: 0;
      left: 1rem;
    }
    .entries-tabs {
      display: flex;
      flex-direction: row;

      h4 {
        order: 2;

        &.active {
          order: 1;
          padding-left: unset;
        }
      }
    }
  }
}
