$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;

$grid-breakpoints-min: (
  xs: 0,
  sm: 577px,
  md: 770px,
  lg: 995px,
  xl: 1201px,
  xxl: 1441px
) !default;

$container-max-widths: (
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1236px,
  xxl: 1600px
);
