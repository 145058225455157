ssab-cx-coils-search {
  .cx-coils {
    @include searchFormAndTable();

    @include media-breakpoint-down(md) {
      @include datePickerTabletAndMobile();
    }

    &-tab {
      padding-bottom: 2rem;
      color: $ssab-white;

      @include media-breakpoint-down(md) {
        @include tabTabletAndMobile();
        white-space: normal;
      }

      .ssab-icon {
        @include media-breakpoint-down(md) {
          filter: unset;
        }
      }
    }

    &-table {
      thead {
        tr th {
          padding: 0 1rem;

          &.stock-mode {
            padding: 0;

            div {
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr td {
          padding: 0 1rem;
        }
      }
    }

    &-body {
      background-color: $ssab-white;

      @include media-breakpoint-down(md) {
        min-height: 50rem;
      }

      .table-responsive {
        overflow-x: auto;
      }

      .icon-color-box {
        height: 20px;
        width: 20px;
        background-color: var(--cx-color-primary);
      }

      .btn-icon {
        &.recerence-coil {
          background-color: var(--cx-color-primary);

          .ssab-icon {
            @include filter-white();
          }
        }

        &.compare-coil {
          background-color: $ssab-button-download-background;
        }
      }
    }

    &-search {
      position: relative;
      padding-bottom: 132px;

      .cx-page-header-title {
        padding-bottom: 1.5rem;
        margin-bottom: 0;
      }

      .cx-page-header-paragraph {
        margin-bottom: 1.5rem;

        @include media-breakpoint-only(md) {
          padding: 0 1.5rem;
        }
        @include media-breakpoint-between(xs, sm) {
          padding: 0 1rem;
        }
      }

      &-option {
        ng-select {
          &.ng-select {
            &,
            &.ng-select-multiple {
              .ng-select-container {
                .ng-arrow-wrapper {
                  transform: rotate(0deg);
                }
              }
            }
          }
        }

        ssab-cx-input-range {
          label {
            &.range-label {
              &.range-control {
                &.text-lg {
                  .ssab-icon {
                    transform: rotate(0deg);
                  }
                }
              }
            }
          }
        }
      }

      .show-stock {
        line-height: 42px;
      }
    }
  }
}
