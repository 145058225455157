ssab-cx-product-carousel{
  @extend %cx-product-carousel;
  padding-top: 2rem;
  display: initial;
  a{
    padding-bottom: unset;
  }
  ssab-cx-carousel{
    display: flex;
  }
  .cx-carousel-content{
    padding: 2.5rem 3.5rem 3rem 0;
    cx-generic-link{
      @include ssab-H4-18();
    }

    @include media-breakpoint-down(md){
      padding-right: 2.5rem;
    }

    @include media-breakpoint-down(sm){
      padding-right: unset;
    }
  }

  cx-media img, cx-media.is-missing{
    height: 120px;
  }

}
