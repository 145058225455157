cx-page-layout.SearchResultsListPageTemplate {

  @include container-max-widths();

  .SearchResultsListSlot {
    @include media-breakpoint-up(lg) {
      padding-right: 1.5rem;
    }
  }

  .cx-page-section {
    filter: drop-shadow(30px 4px 25px rgba(0, 0, 0, 0.05));
  }

  #facetNavigation{
    filter: drop-shadow($ssab-template-drop-shadow);
    @include media-breakpoint-down(md) {
      filter: unset;
    }
  }

  .ProductLeftRefinements {
    margin-top: 250px;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 1.5rem;
    }

    @include media-breakpoint-down(md) {
      padding: unset;
    }
  }
}
