/* Typography from SSAB visual guidelines */
@mixin ssab-H1-70() {
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 70px;
}

@mixin ssab-H1-38() {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 38px;
}

@mixin ssab-H1-24() {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}

@mixin ssab-H2-36() {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
}

@mixin ssab-H2-32() {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
}

@mixin ssab-H3-24() {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

@mixin ssab-H3-20() {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

@mixin ssab-H4-18() {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

@mixin ssab-H5-14() {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}

@mixin ssab-H5-14-min() {
  font-style: normal;
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 2.1px;
}

@mixin ssab-H6-12{
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
}

@mixin ssab-H7-12() {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}

@mixin ssab-H7-12-min() {
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}

@mixin ssab-preamble() {
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
}

@mixin ssab-preamble-min() {
  font-style: normal;
  font-size: 22px;
  line-height: 32px;
}

@mixin ssab-paragraph() {
  //styleName: Paragraph;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

@mixin ssab-paragraph-sm() {
  //styleName: Paragraph text cell Small;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}


@mixin ssab-paragraph-md() {
  //styleName: Paragraph;
  font-size: 15px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
}

@mixin ssab-paragraph-lg() {
  //styleName: Paragraph text cell Large;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

@mixin ssab-caption() {
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
}

@mixin ssab-text-lg(){
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

@mixin ssab-text(){
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}

@mixin ssab-text-md(){
  //styleName: Paragraph text cell Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@mixin ssab-text-sm(){
  //styleName: Paragraph text cell Small;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

@mixin ssab-label-md(){
  //styleName: Input field header Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

@mixin ssab-label-lg(){
  //styleName: Input field header Large;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
