@media print {
  @page landscape {
    size: A3 landscape;
  }

  .coil-comparison-page {
    page: landscape;

    &.modal-open {
      main {
        display: none;
      }

      ssab-cx-coils-comparison {
        position: static;

        &.modal {
          .ssab-modal {
            height: 100%;

            .modal-dialog {
              height: 100%;
              min-height: 100%;
              max-width: 100%;
              margin: 0;

              .modal-content {
                padding: 0;
                height: 100%;
                border: 0;
              }
            }
          }
        }

        .comparison-print-page {
          clear: both;

          + .comparison-print-page {
            page-break-before: always;
          }

          .SiteLogo {
            margin: 0 2.5rem 0 0;
            width: 5.5rem;
          }
        }

        .reference-item {
          flex-direction: row;
        }

        .comparison-table-wrapper {
          overflow: visible;
        }
      }
    }

    cx-page-layout {
      &.AccountPageTemplate {
        .BodyContent {
          filter: none;

          ssab-cx-coils-search {
            max-width: 100%;
            padding: 0;

            .text-white {
              color: $ssab-text-color !important;
            }

            .cx-coils-tab {
              display: none;
            }
          }
        }
      }
    }

    header,
    ssab-cx-footer-container,
    ssab-cx-coils-comparison-preview,
    ssab-cx-background-image,
    .no-print {
      display: none !important;
    }
  }

  .print {
    display: block !important;

    * {
      display: block;
    }
  }

  .ssab-icon {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }
}

@media screen {
  .only-print {
    display: none;
  }
}
