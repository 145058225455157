cx-page-layout.OrderConfirmationPageTemplate {
  @include container-max-widths();

  padding: 2rem 0 3rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;

  cx-page-slot.BodyContent {
    @include media-breakpoint-up(lg) {
      padding: 0 1.5rem;
    }
  }

  ssab-order-confirmation-items, ssab-order-confirmation-header {
    filter: drop-shadow($ssab-template-drop-shadow);
  }

}
